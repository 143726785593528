<template>
    <div class="goodsBox">
        <img class="goodsimg" :src="imgUrl+item.cover" />
        <div class="goodsTitle">
            <div class="title">{{item.title}}</div>
            <div class="nameBox">
                <span>主讲老师：<span v-show='indexs < 2' v-for="(items,indexs) in item.teacher" :key="indexs">{{items.name}}&nbsp;</span></span>
                <div>
                    <img @click="onCollect(item,2)" v-if="item.like" src="@/assets/icon19.png" />
                    <img @click="onCollect(item,2)" v-else src="@/assets/icon71.png" />
                    <span>{{item.like_num}}</span>
                </div>
                <div>
                    <img @click="onCollect(item,1)" v-if="item.collection" src="@/assets/icon34.png" />
                    <img @click="onCollect(item,1)" v-else src="@/assets/icon04.png" />
                    <span>{{item.collection_num}}</span>
                </div>
            </div>
            <div class="priceBox">
                <div class="price"><span>¥</span>{{item.price}}</div>
                <div class="button" @click="goCourseDetails(item.id)">学习</div>
            </div>
        </div>
    </div>
    
</template>

<script>
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useStore } from '@/store/index'
import { 
    collect,
    collectCancel,
    like,
    likeCancel
 } from '@/request/api'

export default defineComponent({
    props:[ 'item' ],
    setup(props,{emit}) {
        const store = useStore();
        const { imgUrl } = storeToRefs(store);
        const router = useRouter();
        const goCourseDetails = (id) => {
            router.push('courseDetails?id='+id)
        }
        // 添加或取消收藏
        const onCollect = (item,type) => {
            if (type == 1) {
                if(!item.collection){
                    collect({
                        parameter_id:item.id,
                        type:1,
                        api_token:localStorage.getItem('token')
                    }).then(res=>{
                        console.log(res,'添加收藏');
                        if(res.data.code == 0){
                            onCurriculumList();
                        }
                    })
                }else{
                    collectCancel({
                        id:item.collection.id,
                        api_token:localStorage.getItem('token')
                    }).then(res=>{
                        console.log(res,'取消收藏');
                        if(res.data.code == 0){
                            onCurriculumList();
                        }
                    })
                }
                
            }else{
                if(!item.like){
                    like({
                        parameter_id:item.id,
                        type:1,
                        api_token:localStorage.getItem('token')
                    }).then(res=>{
                        // console.log(res,'添加收藏');
                        if(res.data.code == 0){
                            onCurriculumList();
                        }
                    })
                }else{
                    likeCancel({
                        id:item.like.id,
                        api_token:localStorage.getItem('token')
                    }).then(res=>{
                        // console.log(res,'取消收藏');
                        if(res.data.code == 0){
                            onCurriculumList();
                        }
                    })
                }
            }
            emit('refresh','')
        }
        return{
            goCourseDetails,
            imgUrl,
            onCollect
        }
    },
})
</script>

<style lang="scss" scoped>
    .goodsBox{
        width: 274px;
        background: #ffffff;
        border-radius: 8px;
        box-shadow: 0px 3px 6px 0px rgba(235,235,235,0.50); 
        .goodsimg{
            width: 274px;
            height: 212px;
        }
        .goodsTitle{
            width: 254px;
            height: 122px;
            padding: 20px 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            .title{
                width: 100%;
                font-size: 18px;
                font-weight: 400;
                text-align: left;
                color: #333333;
                letter-spacing: -0.18px;
                overflow:hidden;  //超出文本隐藏
                text-overflow:ellipsis;  ///超出部分省略号显示
                display:-webkit-box;  //弹性盒模型
                -webkit-box-orient:vertical;  //上下垂直
                -webkit-line-clamp:2;  //自定义行数
            }
            .nameBox{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                span{
                    height: 22px;
                    font-size: 16px;
                    font-weight: 400;
                    text-align: left;
                    color: #999999;
                }
                div{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        width: 20px;
                        height: 20px;
                    }
                    span{
                        height: 20px;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                        color: #fb733d;
                    }
                }
            }
            .priceBox{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .price{
                    height: 29px;
                    font-size: 20px;
                    font-weight: 700;
                    text-align: left;
                    color: #fc6245;
                    span{
                        font-size: 18px;
                    }
                }
                .button{
                    width: 96px;
                    height: 27px;
                    background: linear-gradient(224deg,#ff8c29 13%, #f9634c 95%);
                    border-radius: 14px;
                    font-size: 16px;
                    font-weight: 500;
                    text-align: center;
                    color: #ffffff;
                    line-height: 27px;
                    cursor: pointer;
                }
            }
        }
    }
</style>
