<template>
    <div class="logonBox">
        <div class="box">
            <div class="boxLeft">
                <img src="https://img2.baidu.com/it/u=3074632858,4165691261&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500" />
            </div>
            <div class="boxRight">
                <img @click="onEmit" src="@/assets/icon01.png" class="icon" />
                <div class="calendarBox">
                    <div class="head">{{year}}年{{month}}月</div>
                    <div class="week">
                        <div v-for="(item,index) in weekArr" :key="index">{{item}}</div>
                    </div>
                    <div class="calendar" v-show="contains(item)" v-for="(item,index) in dateArr" :key="index">
                        <div :style="date == items ? 'background:#FC6245;color:#fff;' : ''" v-for="(items,indexs) in item" :key="indexs">{{date == items ? '今天' : items}}<img v-if="isCheck(items)" src="@/assets/icon11.png" /></div>
                    </div>
                </div>
                <div class="button" @click="onCalendar">签到</div>
                <div v-if="(isElmessage==1)" class="elmessage">
                    <span>今日签到成功</span> 
                    <span>获得+2积分</span>
                </div>
                <div v-else-if="(isElmessage==2)" class="elmessage">
                    <span>{{isElmessageTxt}}</span> 
                    <!-- <span>获得+2积分</span> -->
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { defineComponent,reactive,toRefs,onMounted } from 'vue'
import { signList,sign,userDetail } from '@/request/api'
import { useRouter } from 'vue-router'

export default defineComponent({
    setup(props,{emit}) {
        const router = useRouter();
        const state = reactive({
            weekArr:['日','一','二','三','四','五','六'],
            today : new Date(),
            year : '',
            month : '',
            day : '', //一周中的第几天，0代表周日，1代表周一
            date : '',
            dateArr: [],
            checkin: ['2022-12-05'],
            isElmessage:0,
            isElmessageTxt:'签到失败'
        })
        onMounted(()=>{
            state.year = state.today.getFullYear();
			state.month = state.today.getMonth() + 1;
			state.day = state.today.getDay();
			state.date = state.today.getDate();
            getCalendar();
            signList({api_token:localStorage.getItem('token')}).then(res=>{
                state.checkin = res.data.data;
            })
        })
        const onEmit = () => {
            emit('onClose',false)
        }
        const onCalendar = () => {
            sign({api_token:localStorage.getItem('token')}).then(res=>{
                if(res.data.code==0){
                    state.isElmessage = 1;
                    setTimeout(()=>{
                        userDetail({api_token:localStorage.getItem('token')}).then(res=>{})
                        router.go(0);
                    },1500) 
                }else{
                    state.isElmessage = 2;
                    state.isElmessageTxt = res.data.msg;
                }
                signList({api_token:localStorage.getItem('token')}).then(res=>{
                    state.checkin = res.data.data;
                })
                setTimeout(()=>{
                    state.isElmessage = 0;
                },1000);
            })
        }
        const isLeap = () => {
            const year = state.year;
            if(year % 4 == 0 && year % 100 > 0) {
                return true;
            }else if(year % 400 == 0 && year % 3200 > 0) {
                return true;
            }else {
                return false;
            }
        }
        const getLen = () => {
            const month = state.month;
            if(month == 2) {
                if(isLeap()) {
                    return 29;
                }else {
                    return 28;
                }
            }else {
                if(month < 8) {
                    if(month % 2 > 0) {
                        return 31;
                    }else {
                        return 30;
                    }
                }else {
                    if(month % 2 > 0) {
                        return 30;
                    }else {
                        return 31;
                    }
                }
            }
        }
        const getCalendarTime = () => {
            return state.year + '-' + state.month + '-' + state.date;
        }
        const getCalendar = () => {
            var len = getLen();
            var d = new Date(state.year, state.month - 1, 1);
            var dfw = d.getDay();
            var arr = new Array();
            var tem = 0;
            for (var i = 0; i < 6; i++) {
                arr[i] = new Array();
                for (var j = 0; j < 7; j++) {
                    tem++;
                    if (tem - dfw > 0 && tem - dfw <= len) {
                        arr[i][j] = tem - dfw;
                    } else {
                        arr[i][j] = "";
                    }
                }
            }
            state.dateArr = arr;
            // state.dateArr.length = 5;
        }
        //切换到下个月
        const nextMonth = () => {
            if(state.month == 12) {
                state.year ++;
                state.month = 1;
            }else {
                state.month ++;
            };
            getCalendar();
        }
        //切换到上个月
        const prevMonth = () => {
            if(state.month == 1) {
                state.year --;
                state.month = 12;
            }else {
                state.month --;
            }
            getCalendar();
        }
        const contains = (arr) => {
            if((arr[0] == '') && (arr[1] == '') && (arr[2] == '') && (arr[3] == '') && (arr[4] == '') && (arr[5] == '') && (arr[6] == '')) {
                return false;
            }else {
                return true;
            }
        }
        const isCheck = (index) => {
            const date = `${state.year}-${state.month<10?'0'+state.month:state.month}-${index<10?'0'+index:index}`
            for(let i in state.checkin) {
                // console.log(date,state.checkin[i]);
                if(date == state.checkin[i]) {
                    return true;
                }
            }
            return false;
        }
        
        return{
            ...toRefs(state),
            isCheck,
            contains,
            prevMonth,
            nextMonth,
            getCalendar,
            getCalendarTime,
            getLen,
            isLeap,
            onCalendar,
            onEmit
        }
    },
})
</script>

<style lang="scss" scoped>
    .logonBox{
        width: 100%;
        height: 100vh;
        background-color: rgba($color: #000000, $alpha: 0.7);
        position: relative;
        .box{
            width: 1200px;
            height: 600px;
            background: linear-gradient(208deg,#f5f8fe 17%, #f2f6ff 14%, #fafbff 54%, #fdfdfe 100%, #ffffff 97%);
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-content: center;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -300px;
            margin-left: -600px;
            .boxLeft{
                width: 50%;
                height: 100%;
                // background-image: url(https://img2.baidu.com/it/u=3074632858,4165691261&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500);
                // background-size: 100% 100%;
                position: relative;
                img{
                    width: 100%;
                    height: 100%;
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                }
            }
            .boxRight{
                width: 50%;
                height: 100%;
                background-image: url(@/assets/back02.png);
                background-size: 100% 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                position: relative;
                .icon{
                    width: 33px;
                    height: 33px;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
                .calendarBox{
                    width: 354px;
                    height: 413px;
                    background-image: url(@/assets/back04.png);
                    background-size: 100% 100%;
                    padding: 0 57px;
                    .head{
                        width: 100%;
                        height: 58px;
                        font-size: 18px;
                        font-family: PingFang SC, PingFang SC-Regular;
                        font-weight: 400;
                        text-align: center;
                        color: #fc6245;
                        line-height: 58px;
                    }
                    .week{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 25px 0 0px 0;
                        div{
                            width: 38px;
                            height: 38px;
                            font-size: 16px;
                            font-family: PingFang SC, PingFang SC-Regular;
                            font-weight: 400;
                            text-align: center;
                            color: #333333;
                        }
                    }
                    .calendar{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        div{
                            width: 38px;
                            height: 38px;
                            background: #f0f0f0;
                            border-radius: 50%;
                            font-size: 16px;
                            font-weight: 400;
                            text-align: center;
                            color: #333333;
                            line-height: 38px;
                            margin-bottom: 15px;
                            position: relative;
                            cursor: pointer;
                            img{
                                width: 20px;
                                height: 18px;
                                position: absolute;
                                top: 9px;
                                left: 9px;
                            }
                        }
                    }
                }
                .button{
                    width: 468px;
                    height: 47px;
                    background: linear-gradient(262deg,#ff8c29 9%, #f9634c 94%);
                    border-radius: 24px;
                    font-size: 18px;
                    font-family: PingFang SC, PingFang SC-Medium;
                    font-weight: 500;
                    text-align: center;
                    color: #ffffff;
                    line-height: 47px;
                    margin-top: 30px;
                    cursor: pointer;
                }
                .elmessage{
                    width: 238px;
                    height: 93px;
                    opacity: 0.7;
                    background: #000000;
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    position: absolute;
                    top: calc(50% - 46px);
                    left: calc(50% - 119px);
                    span{
                        font-size: 18px;
                        font-family: PingFang SC, PingFang SC-Regular;
                        font-weight: 400;
                        text-align: center;
                        color: #ffffff;
                        letter-spacing: 0.72px;
                    }
                }
            }
        }
    }
</style>
