import { defineStore } from "pinia";
import { reactive, ref, toRefs } from 'vue'

export const useStore = defineStore('main',() => {
    const searchValue = ref('')
    const state = reactive({
        imgUrl:'https://qianchao-sheke.oss-cn-hangzhou.aliyuncs.com/',
        storeCurriculumDetail:[]
    })
    function onSearchValue() {
        console.log(searchValue,'这里是store');
    }

    return { 
        ...toRefs(state),
        searchValue,
        onSearchValue 
    }
})
