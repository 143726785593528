<template>
    <div class="logonBox" v-if="information.loginSteps == 1">
        <div class="box">
            <div class="boxLeft">
                <img src="@/assets/logo.png" />
                <div>欢迎使用 设科教育</div>
            </div>
            <div class="boxRight">
                <img class="guanIcon" @click="onShut" src="@/assets/icon01.png" />
                <div v-if="isForgot">
                    <div class="titleBoxAll">
                        <div class="titleBox" @click="isPass = true">
                            <!-- <img src="@/assets/icon02.png" /> -->
                            <div :style="isPass ? 'color: #FC6245;' : ''">密码登录</div>
                            <div :style="isPass ? 'background: #FC6245;' : ''"></div>
                        </div>
                        <div class="titleBox" @click="isPass = false">
                            <!-- <img src="@/assets/icon02.png" /> -->
                            <div :style="!isPass ? 'color: #FC6245;' : ''">手机号登录</div>
                            <div :style="!isPass ? 'background: #FC6245;' : ''"></div>
                        </div>
                    </div>
                    
                    <div class="inputBox">
                        <div class="input">
                            <input v-model="phone" placeholder="请输入手机号" />
                        </div>
                        <div class="input code" v-if="isPass">
                            <input type="password" v-model="pass" placeholder="请输入密码" />
                            <div style="border-left: 1px solid transparent;" @click="isForgot = false">忘记密码</div>
                        </div>
                        <div class="input code" v-else>
                            <input v-model="code" placeholder="请输入验证码" />
                            <div @click="getCode">{{count}}</div>
                        </div>
                    </div>
                    <p class="zhus">登录即默认注册哟～</p>
                    <div class="button" @click="onLogo(1)">登录</div>
                    <div class="agreement">
                        <div class="dian" @click="onAgreement"><div v-if="isAgreement"></div></div>
                        <p>勾选代表您已同意</p>
                        <span @click="goAgreement(3)">《用户注册协议》</span>
                        <span @click="goAgreement(2)">《隐私协议》</span>
                    </div>
                </div>
                <div v-else>
                    <div class="inputBox">
                        <div class="input">
                            <input v-model="forgotPhone" placeholder="请输入手机号" />
                        </div>
                        <div class="input code">
                            <input v-model="forgotCode" placeholder="请输入验证码" />
                            <div @click="getCode">{{count}}</div>
                        </div>
                        <div class="input code">
                            <input :type="isPass1 ? 'password' : 'text'" v-model="password" placeholder="请输入登录密码" />
                            <div style="border-left: 1px solid transparent;"></div>
                            <img v-if="isPass1" @click="isPass1 = !isPass1" src="@/assets/icon78.png" />
                            <img v-else @click="isPass1 = !isPass1" src="@/assets/icon79.png" />
                        </div>
                        <div class="input code">
                            <input :type="isPass2 ? 'password' : 'text'" v-model="password_confirmation" placeholder="请输入确认登录密码" />
                            <div style="border-left: 1px solid transparent;"></div>
                            <img v-if="isPass2" @click="isPass2 = !isPass2" src="@/assets/icon78.png" />
                            <img v-else @click="isPass2 = !isPass2" src="@/assets/icon79.png" />
                        </div>
                    </div>
                    <div class="button" style="margin-top:55px;" @click="forgotPassword">下一步</div>
                </div>
            </div>
        </div>
    </div>

    <div class="logonBox informationBox">
        <div class="box">
            <div class="boxLeft boxLefts">
                <div class="buzhou">{{information.loginSteps - 1}}/<span>2</span></div>
            </div>
            <div class="boxRight">
                <img class="guanIcon" src="@/assets/icon01.png" @click="onShut" />
                <div class="informationCenter">
                    <div class="titleCenter">信息收集</div>
                    <div v-if="information.loginSteps == 2"> 
                        <div class="inputs">
                            <div class="div">
                                <div class="inputsLeft"><span>*</span> 姓名</div>
                                <input @blur="onblur(1)" v-model="information.name.value" placeholder="输入姓名" />
                            </div>
                            <span v-if="information.name.isShow" class="zhu">姓名不得为空</span>
                        </div>
                        <div class="inputs">
                            <div class="div">
                                <div class="inputsLeft"><span>*</span> 电话</div>
                                <input disabled @blur="onblur(2)" v-model="information.phone.value" placeholder="输入电话" />
                            </div>
                            <span v-if="information.phone.isShow" class="zhu">手机号不能为空或填写正确的手机号</span>
                        </div>
                        <div class="inputs">
                            <div class="div">
                                <div class="inputsLeft"><span>*</span> 身份证号</div>
                                <input @blur="onblur(3)" v-model="information.IDCard.value" placeholder="输入身份证号" />
                            </div>
                            <span v-if="information.IDCard.isShow" class="zhu">身份证号不得为空或填写正确的身份证号</span>
                        </div>
                        <div class="inputs"><span class="zhu">身份证号仅作为学习证书发放使用，不会透露给任何人～</span></div>
                        <div class="inputs">
                            <div class="div">
                                <div class="inputsLeft">单位名称</div>
                                <input @blur="onblur(7)" v-model="information.company.value" placeholder="输入单位名称" />
                            </div>
                            <span v-if="information.company.isShow" class="zhu">单位名称不得为空</span>
                        </div>
                        <div class="inputs">
                            <div class="div">
                                <div class="inputsLeft"><span>*</span> 邮箱</div>
                                <input @blur="onblur(4)" v-model="information.mailbox.value" placeholder="输入邮箱" />
                            </div>
                            <span v-if="information.mailbox.isShow" class="zhu">邮箱不得为空</span>
                        </div>
                        <div class="isExtension">
                            <p>是否愿意作为推广员</p>
                            <div class="chank">
                                <div @click="onblur(5)" class="chanks"><div v-if="!information.isExtension"></div></div>
                                <span>否</span>
                            </div>
                            <div class="chank">
                                <div @click="onblur(6)" class="chanks"><div v-if="information.isExtension"></div></div>
                                <span>是</span>
                            </div>
                        </div>
                        <div class="button" @click="onLogo(2)">下一步</div>
                    </div>
                    <div v-else-if="information.loginSteps == 3">
                        <div class="ageInformation">
                            <div class="stem">您的年龄是？</div>
                            <div class="optionAll">
                                <div @click="information.age.value = '25岁-30岁'" :class="information.age.value == '25岁-30岁'?'check':''" class="option">25岁-30岁</div>
                                <div @click="information.age.value = '31岁-39岁'" :class="information.age.value == '31岁-39岁'?'check':''" class="option">31岁-39岁</div>
                                <div @click="information.age.value = '40岁以上'" :class="information.age.value == '40岁以上'?'check':''" class="option">40岁以上</div>
                            </div>
                        </div>
                        <div class="ageInformation">
                            <div class="stem">您的学历是？</div>
                            <div class="optionAll">
                                <div @click="information.xueli.value = '高中'" :class="information.xueli.value == '高中'?'check':''" class="option">高中</div>
                                <div @click="information.xueli.value = '大专'" :class="information.xueli.value == '大专'?'check':''" class="option">大专</div>
                                <div @click="information.xueli.value = '本科'" :class="information.xueli.value == '本科'?'check':''" class="option">本科</div>
                                <div @click="information.xueli.value = '硕士及以上'" :class="information.xueli.value == '硕士及以上'?'check':''" class="option">硕士及以上</div>
                            </div>
                        </div>
                        <div class="button" @click="onLogo(3)">完成</div>
                    </div>
                    <div class="tips">请谨慎填写后期无法更改</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent,reactive,toRefs } from 'vue'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { login,guest,userDetail,passwordLogin,setPassword } from '@/request/api'
import {
    updateDetail
} from '@/request/api'
export default defineComponent({
    setup(props,{emit}) {
        const router = useRouter();
        const state = reactive({
           isAgreement: false,
           count: '获取验证码',
           codeShow:false,
           timer:'',
           phone:'',
           code:'',
           isPass:true,
           pass:'',
           forgotPhone:'',
           forgotCode:'',
           password:'',
           password_confirmation:'',
           isForgot:true,
           isPass1:true,
           isPass2:true
        })
        const information = reactive({
           name:{
            value:'',
            isShow:false
           },
           phone:{
            value:'',
            isShow:false
           },
           IDCard:{
            value:'',
            isShow:false
           },
           mailbox:{
            value:'',
            isShow:false
           },
           company:{
            value:'',
            isShow:false
           },
           age:{
            value:'25岁-30岁',
            isShow:false
           },
           xueli:{
            value:'高中',
            isShow:false
           },
           isExtension:false,
           loginSteps:1
        })
        const forgotPassword = () => {
            var patt = /^[1][3-9][\d]{9}/;
            if (!patt.test(state.forgotPhone)) return ElMessage({message: '手机号不能为空或填写正确的手机号',type: 'warning',})
            if (!state.forgotCode) return ElMessage({message: '验证码不能为空',type: 'warning',})
            if (!state.password) return ElMessage({message: '密码不能为空',type: 'warning',})
            if (!state.password_confirmation) return ElMessage({message: '确认密码不能为空',type: 'warning',})
            setPassword({
                // api_token:localStorage.getItem('token'),
                phone:state.forgotPhone,
                code:state.forgotCode,
                password:state.password,
                password_confirmation:state.password_confirmation
            }).then(res=>{
                // console.log(res,'登录');
                if (res.data.code == 0) {
					console.log('jinlaile')
                    userDetail({api_token:localStorage.getItem('token')}).then(res=>{
                        // console.log(res,'个人信息')
                        information.phone.value = res.data.data.phone;
                        if(res.data.code == 0){
                            state.isForgot = true;
                        }
                    })
                }else{
                    ElMessage({message: res.data.msg,type: 'warning',})
                }
            })
        }
        const goAgreement = (type) => {
            onShut();
            router.push("userAgreement?type="+type);
        }
        const onShut = () => {
            emit('onShut',false)
        } 
        const onLogo = (type) => {
            var patt = /^[1][3-9][\d]{9}/;
            var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
            if (type == 1) {
                if(!state.isAgreement){
                    ElMessage({
                        message: '阅读并选中《用户注册协议》《隐私协议》',
                        type: 'warning',
                    })
                    return;
                }
                if (!patt.test(state.phone)) return ElMessage({message: '手机号不能为空或填写正确的手机号',type: 'warning',})
                if (state.isPass) {
                    if (!state.pass) return ElMessage({message: '密码不能为空',type: 'warning',})
                    passwordLogin({
                        phone:state.phone,
                        password:state.pass
                    }).then(res=>{
                        // console.log(res,'登录');
                        if (res.data.code == 0) {
							console.log('登录');
                            localStorage.setItem('token',res.data.data.api_token);
                            userDetail({api_token:res.data.data.api_token}).then(resd=>{
                                // console.log(resd,'个人信息')
                                information.phone.value = resd.data.data.phone;
                                if(!resd.data.data.id_card){ 
                                    information.loginSteps = 2;
                                    emit('onAlready',true)
                                    return;
                                }
                                emit('onAlready',true)
                                onShut();
                                return;
                            })
                        }else{
                            ElMessage({message: res.data.msg,type: 'warning',})
                        }
                    })
                    return;
                }
                if (!state.code) return ElMessage({message: '验证码不能为空',type: 'warning',})
				console.log('个人信息login')
                login({
                    phone:state.phone,
                    code:state.code
                }).then(res=>{
                    console.log(res,'登录');
                    if (res.data.code == 0) {
						console.log('个人信息login')
                        userDetail({api_token:res.data.data.api_token}).then(res=>{
                            console.log(res,'个人信息login')
                            information.phone.value = res.data.data.phone;
                            if(res.data.data.is_password == 0){  //密码是否设置 1是，0否
                                state.isForgot = false;
                                // emit('onAlready',true)
                                return;
                            }
                            if(!res.data.data.id_card){ 
                                information.loginSteps = 2;
                                emit('onAlready',true)
                                return;
                            }
                            emit('onAlready',true)
                            onShut();
                            return;
                        })
                    }else{
                            ElMessage({message: res.data.msg,type: 'warning',})
                        }
                })
            }else if(type == 2){
                console.log(patt.test(information.phone.value));
                !information.name.value ? information.name.isShow = true : information.name.isShow = false
                !information.phone.value ? information.phone.isShow = true : information.phone.isShow = false
                !patt.test(information.phone.value) ? information.phone.isShow = true : information.phone.isShow = false
                !information.IDCard.value ? information.IDCard.isShow = true : information.IDCard.isShow = false
                !reg.test(information.IDCard.value) ? information.IDCard.isShow = true : information.IDCard.isShow = false
                !information.mailbox.value ? information.mailbox.isShow = true : information.mailbox.isShow = false
                !information.company.value ? information.company.isShow = true : information.company.isShow = false
                if (!information.name.isShow && !information.phone.isShow && !information.IDCard.isShow && !information.mailbox.isShow && !information.company.isShow) {
                    updateDetail({
                        nickname:information.name.value,
                        id_card:information.IDCard.value,
                        company:information.company.value,
                        email:information.mailbox.value,
                        api_token:localStorage.getItem('token'),
                        is_tuijian:information.isExtension ? 1:0
                    }).then(res=>{
                        if (res.data.code == 0) {
                            information.loginSteps = 3;
                        }
                    })
                }
            }else{
                updateDetail({
                    age:information.age.value,
                    xueli:information.xueli.value,
                    api_token:localStorage.getItem('token')
                }).then(res=>{
                    if (res.data.code == 0) {
                        onShut();
                    }
                })
            }
        }
        const onblur = (type) => {
            var patt = /^[1][3-9][\d]{9}/;
            var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
            !information.name.value && type == 1 ? information.name.isShow = true : type == 1 ? information.name.isShow = false : ''
            !information.phone.value && type == 2 ? information.phone.isShow = true : type == 2 ? information.phone.isShow = false : ''
            !patt.test(information.phone.value) && type == 2 ? information.phone.isShow = true : information.phone.isShow = false
            !information.IDCard.value && type == 3 ? information.IDCard.isShow = true : type == 3 ? information.IDCard.isShow = false : ''
            !reg.test(information.IDCard.value) && type == 3 ? information.IDCard.isShow = true : information.IDCard.isShow = false
            !information.mailbox.value && type == 4 ? information.mailbox.isShow = true : type == 4 ? information.mailbox.isShow = false : ''
            !information.company.value && type == 7 ? information.company.isShow = true : type == 7 ? information.company.isShow = false : ''
            type == 5 ? information.isExtension = false : type == 6 ? information.isExtension = true : ''
        }
        const onAgreement = () => {
            state.isAgreement = !state.isAgreement;
        }
        const getCode = () => {
            if (state.count > 0 && state.count <= 60) return ElMessage('正在发送请稍后再试')
            guest({phone:state.phone}).then(res=>{
                console.log(res,'验证码')
            })
            state.count = 59;
            state.timer = setInterval(() => {
                if (state.count > 0 && state.count <= 60) {
                    state.count--;
                } else {
                    clearInterval(state.timer);
                    state.timer = null;
                    state.count = '再次获取验证码';
                }
            }, 1000);
        }
        return{
            ...toRefs(state),
            information,
            router,
            onAgreement,
            getCode,
            onblur,
            onLogo,
            onShut,
            goAgreement,
            forgotPassword
        }
    }
})
</script>

<style lang="scss" scoped>
    .logonBox{
        width: 100%;
        height: 100vh;
        background-color: rgba($color: #000000, $alpha: 0.7);
        position: relative;
        .box{
            width: 1200px;
            height: 600px;
            background: linear-gradient(208deg,#f5f8fe 17%, #f2f6ff 14%, #fafbff 54%, #fdfdfe 100%, #ffffff 97%);
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-content: center;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -300px;
            margin-left: -600px;
            .boxLefts{
                background-image: url(@/assets/back03.png) !important;
                .buzhou{
                    // width: 100%;
                    text-align: center;
                    left: 278px;
                    span{
                        width: 14px;
                        height: 33px;
                        font-size: 24px;
                        font-weight: 500;
                        text-align: right;
                        color: #999999;
                        letter-spacing: 1.2px;
                    }
                }
            }
            .boxLeft{
                width: 50%;
                height: 100%;
                background-image: url(@/assets/back01.png);
                background-size: 100% 100%;
                position: relative;
                img{
                    width: 154px;
                    height: 46px;
                    position: absolute;
                    top: 67px;
                    left: 111px;
                }
                div{
                    height: 30px;
                    font-size: 22px;
                    font-weight: 500;
                    text-align: left;
                    color: #FC6245;
                    letter-spacing: 3.96px;
                    position: absolute;
                    bottom: 86px;
                    left: 111px;
                }
            }
            .boxRight{
                width: 50%;
                height: 100%;
                background-image: url(@/assets/back02.png);
                background-size: 100% 100%;
                display: flex;
                justify-content: center;
                align-content: center;
                flex-direction: column;
                position: relative;
                .guanIcon{
                    width: 33px;
                    height: 33px;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
                .titleBoxAll{
                    width: 462px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin: 0 auto;
                }
                .titleBox{
                    width: 120px;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    flex-direction: column;
                    margin: 40px 60px 40px 0;
                    cursor: pointer;
                    // img{
                    //     width: 20px;
                    //     height: 30px;
                    //     margin-right: 14px;
                    // }
                    div:nth-child(1){
                        width: 120px;
                        height: 33px;
                        font-size: 24px;
                        font-family: PingFang SC, PingFang SC-Medium;
                        font-weight: 500;
                        text-align: center;
                        color: #333333;
                        margin-bottom: 8px;
                    }
                    div:nth-child(2){
                        width: 60px;
                        height: 2px;
                        background: transparent;
                        border-radius: 1px;
                        margin: 0 auto;
                    }
                }
                .inputBox{
                    width: 462px;
                    margin: 0 auto;
                    .input{
                        width: 100%;
                        border-bottom: 0.5px solid #dbdbdb;
                        input{
                            width: 100%;
                            height: 25px;
                            padding: 14px 0;
                            outline: none;
                            border: none;
                            background-color: transparent;
                        }
                    }
                    .code{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-bottom: 0.5px solid #dbdbdb;
                        position: relative;
                        input{
                            width: 70%;
                        }
                        div{
                            width: 30%;
                            height: 100%;
                            font-size: 18px;
                            font-weight: 400;
                            color: #FC6245;
                            line-height: 25px;
                            // padding: 14px 0;
                            text-align: center;
                            border-left: 1px solid #dbdbdb;
                            cursor:pointer;
                        }
                        img{
                            width: 16px;
                            height: 12px;
                            position: absolute;
                            bottom: 20px;
                            right: 12px;
                        }
                    }
                }
                .zhus{
                    width: 77%;
                    height: 22px;
                    font-size: 12px !important;
                    font-weight: 400;
                    text-align: left;
                    color: #FC6245;
                    margin: 18px auto;
                    margin-bottom: 40px;
                }
                .button{
                    width: 468px;
                    height: 47px;
                    background: linear-gradient(262deg,#ff8c29 9%, #f9634c 94%);
                    border-radius: 30px;
                    font-size: 18px;
                    font-weight: 500;
                    text-align: center;
                    color: #ffffff;
                    line-height: 47px;
                    margin: 0 auto;
                    cursor:pointer;
                }
                .agreement{
                    width: 468px;
                    height: 17px;
                    font-size: 12px;
                    font-weight: 400;
                    text-align: left;
                    color: #333333;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin: 30px auto;
                    .dian{
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        border: 1px solid #FC6245;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor:pointer;
                        margin-right: 9px;
                        div{
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            background: #FC6245;
                        }
                    }
                    p{
                        margin: 0;
                    }
                    span{
                        color: #FC6245;
                        cursor: pointer;
                    }
                }
                .informationCenter{
                    width: 462px;
                    margin: 0 auto;
                    .titleCenter{
                        width: 96px;
                        height: 33px;
                        font-size: 24px;
                        font-weight: 500;
                        text-align: left;
                        color: #333333;
                        margin-bottom: 25px;
                    }
                    .tips{
                        width: 100%;
                        text-align: center;
                        font-size: 12px;
                        color: #ef3700;
                        margin-top: 10px;
                    }
                    .inputs{
                        width: 100%;
                        .div{
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 15px 0;
                            border-bottom: 0.5px solid #dbdbdb;
                            .inputsLeft{
                                width: 20%;
                                height: 25px;
                                font-size: 18px;
                                font-weight: 400;
                                text-align: left;
                                border-right: 0.5px solid #dbdbdb;
                                color: #333333;
                                span{
                                    color: #FC6245;
                                }
                            }
                            input{
                                width: 76%;
                                height: 25px;
                                font-size: 18px;
                                font-weight: 400;
                                text-align: left;
                                color: #999999;
                                border: none;
                                outline:none;
                                padding: 0 2%;
                                background-color: transparent;
                            }
                        }
                        .zhu{
                            height: 22px;
                            font-size: 12px;
                            font-weight: 400;
                            text-align: left;
                            color: #fc6245;
                            margin-left: 20%;
                        }
                    }
                    .isExtension{
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        p{
                            height: 17px;
                            font-size: 12px;
                            font-weight: 400;
                            text-align: left;
                            color: #333333;
                            margin-right: 37px;
                        }
                        .chank{
                            width: 40px;
                            height: 16px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-right: 25px;
                            .chanks{
                                width: 16px;
                                height: 16px;
                                border: 1px solid #FC6245;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                cursor: pointer;
                                div{
                                    width: 10px;
                                    height: 10px;
                                    border-radius: 50%;
                                    background: #FC6245;
                                }
                            }
                        }
                    }
                    .button{
                        width: 468px;
                        height: 47px;
                        font-size: 18px;
                        font-weight: 500;
                        text-align: center;
                        color: #ffffff;
                        margin-top: 30px;
                    }
                    .ageInformation{
                        width: 100%;
                        .stem{
                            height: 25px;
                            font-size: 18px;
                            font-weight: 400;
                            text-align: left;
                            color: #333333;
                        }
                        .optionAll{
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex-wrap: wrap;
                            .option{
                                width: 224px;
                                height: 47px;
                                border: 1px solid #FDF2F0;
                                border-radius: 25px;
                                font-size: 16px;
                                font-weight: 400;
                                text-align: center;
                                color: #FC6245;
                                line-height: 47px;
                                margin: 20px 0;
                                cursor:pointer;
                            }
                            .check{
                                background: #FDF2F0;
                                color: #FC6245;
                            }
                        }
                    }
                }
            }
        }
    }
    .informationBox{
        .boxRight{

        }
    }
</style>



  