
//导入request.js
import request from "@/request/index";

//登录
export const login = (params) => request.get("/api/user/login",{params});

//获取个人信息
export const userDetail = (params) => request.get("/api/user/detail",{params});

//课程列表
export const curriculumList = (params) => request.get("/api/curriculum/curriculumList",{params});

//添加收藏
export const collect = (params) => request.get("/api/user/collect",{params});

//取消收藏
export const collectCancel = (params) => request.get("/api/user/collectCancel",{params});

//添加点赞
export const like = (params) => request.get("/api/user/like",{params});

//取消点赞
export const likeCancel = (params) => request.get("/api/user/likeCancel",{params});

//公共配置
export const setting = (params) => request.get("/api/public/setting",{params});

//资讯列表
export const newsList = (params) => request.get("/api/public/newsList",{params});

//资讯详情
export const newsDetail = (params) => request.get("/api/public/newsDetail",{params});

//资讯评论列表
export const newsCommentList = (params) => request.get("/api/public/newsCommentList",{params});

//资讯分享
export const newsShare = (params) => request.get("/api/public/newsShare",{params});

//评论课程或资讯
export const comment = (params) => request.get("/api/user/comment",{params});

//秒杀时间段列表
export const seckillList = (params) => request.get("/api/curriculum/seckillList",{params});

//秒杀课程列表
export const seckillDetailLis = (params) => request.get("/api/curriculum/seckillDetailList",{params});

//拼团商品列表
export const groupCurriculumList = (params) => request.get("/api/curriculum/groupCurriculumList",{params});

//发送短信
export const guest = (params) => request.get("/api/public/guest",{params});

//本月签到记录
export const signList = (params) => request.get("/api/user/signList",{params});

//签到
export const sign = (params) => request.get("/api/user/sign",{params});

//课程分类
export const classifyList = (params) => request.get("/api/curriculum/classifyList",{params});

//我的购物车
export const myCart = (params) => request.get("/api/user/myCart",{params});

//课程详情
export const curriculumDetail = (params) => request.get("/api/curriculum/curriculumDetail",{params});

//课程老师
export const teacher = (params) => request.get("/api/curriculum/teacher",{params});

//课程老师
export const addCart = (params) => request.get("/api/user/addCart",{params});

//课程章节列表
export const sectionList = (params) => request.get("/api/curriculum/sectionList",{params});

//课程评论列表
export const commentList = (params) => request.get("/api/curriculum/commentList",{params});

//章节观看完毕回调
export const userCurriculumDetailStudy = (params) => request.get("/api/user/user_curriculum_detail_study",{params});

//正在学习课程详情
export const userCurriculumStudyDetail = (params) => request.get("/api/user/user_curriculum_study_detail",{params});

//学习记录
export const userCurriculumStudy = (params) => request.get("/api/user/user_curriculum_study",{params});

//学习统计
export const studyCount = (params) => request.get("/api/user/studyCount",{params});

//积分优惠券列表
export const integralCoupon = (params) => request.get("/api/public/integralCoupon",{params});

//积分兑换优惠券
export const integralExchangeCoupon = (params) => request.get("/api/user/integralExchangeCoupon",{params});

//积分明细
export const userIntegral = (params) => request.get("/api/user/userIntegral",{params});

//课程分类
export const myClassify = (params) => request.get("/api/user/myClassify",{params});

//真题试炼
export const zhenti = (params) => request.get("/api/curriculum/zhenti",{params});

//历年考题年份
export const questionYear = (params) => request.get("/api/curriculum/question_year",{params});

//历年考题
export const yearQuestion = (params) => request.get("/api/curriculum/year_question",{params});

//课后练习列表
export const lianxi = (params) => request.get("/api/curriculum/lianxi",{params});

//考试题目列表
export const examinationQuestionList = (params) => request.get("/api/examination/examinationQuestionList",{params});

//考试答题
export const userExamination = (params) => request.get("/api/examination/userExamination",{params});

//练习答题
export const answerQuestions = (params) => request.get("/api/user/answer_questions",{params});

//练习答题结果
export const view_answer_questions = (params) => request.get("/api/user/view_answer_questions",{params});

//查看考试结果
export const viewUserExamination = (params) => request.get("/api/examination/viewUserExamination",{params});

//考试错题解析
export const examinationErrorAnalysis = (params) => request.get("/api/examination/error_analysis",{params});

//错题解析
export const errorAnalysi = (params) => request.get("/api/curriculum/error_analysis",{params});

//模拟考试列表
export const examinationList = (params) => request.get("/api/examination/examinationList",{params});

//公告/系统通知列表
export const noticeList = (params) => request.get("/api/public/noticeList",{params});

//公告/系统通知详情
export const noticeDetail = (params) => request.get("/api/public/noticeDetail",{params});

//我的优惠券
export const myCoupon = (params) => request.get("/api/user/myCoupon",{params});

//资金明细
export const userPrice = (params) => request.get("/api/user/userPrice",{params});

//申请提现
export const userTi = (params) => request.get("/api/user/userTi",{params});

//我得发票抬头
export const invoiceTitle = (params) => request.get("/api/user/invoiceTitle",{params});

//添加发票抬头
export const addUserInvoiceTitle = (params) => request.get("/api/user/addUserInvoiceTitle",{params});

//我的发票
export const myInvoice = (params) => request.get("/api/user/myInvoice",{params});

//发票详情
export const myInvoiceDetail = (params) => request.get("/api/user/myInvoiceDetail",{params});

//申请开票
export const invoice = (params) => request.get("/api/user/invoice",{params});

//可开发票订单
export const userInvoiceOrderList = (params) => request.get("/api/order/user_invoice_order_list",{params});

//我的收藏
export const myCollect = (params) => request.get("/api/user/myCollect",{params});

//我的课程
export const myCurriculum = (params) => request.get("/api/user/myCurriculum",{params});

//实名认证
export const renzheng = (params) => request.get("/api/user/renzheng",{params});

//修改个人信息
export const updateDetail = (params) => request.get("/api/user/updateDetail",{params});

//邀请二维码
export const erweima = (params) => request.get("/api/user/erweima",{params});

//佣金统计
export const commissionCount = (params) => request.get("/api/user/commissionCount",{params});

//佣金明细
export const commissionList = (params) => request.get("/api/user/commissionList",{params});

//订单列表
export const orderList = (params) => request.get("/api/order/orderList",{params});

//订单列表过期时间
export const xitong = (params) => request.get("/api/public/xitong",{params});

//未支付订单取消
export const orderCancel = (params) => request.get("/api/order/orderCancel",{params});

//拼团订单取消
export const groupOrderCancel = (params) => request.get("/api/order/groupOrderCancel",{params});

//正式考试场次列表
export const user_examination_session = (params) => request.get("/api/examination/user_examination_session",{params});

//正式考试场次详情
export const user_examination_session_detail = (params) => request.get("/api/examination/user_examination_session_detail",{params});

//刷脸
export const face_swiping = (params) => request.get("/api/user/face_swiping",{params});

//证书类型列表
export const certificate = (params) => request.get("/api/public/certificate",{params});

//申请开证书
export const certificateApply = (params) => request.get("/api/user/certificateApply",{params});

//我的证书
export const myCertificate = (params) => request.get("/api/user/myCertificate",{params});

//我的成绩
export const chengji = (params) => request.get("/api/examination/chengji",{params});

//课程拼团信息
export const curriculumGroupInfo = (params) => request.get("/api/curriculum/groupInfo",{params});

//错题库
export const errorQuestion = (params) => request.get("/api/curriculum/errorQuestion",{params});

//删除购物车商品
export const cartDel = (params) => request.get("/api/user/cartDel",{params});

//清空购物车商品
export const cartClear = (params) => request.get("/api/user/cartClear",{params});

//系统消息列表
export const messageList = (params) => request.get("/api/public/messageList",{params});

//系统消息详情
export const messageDetail = (params) => request.get("/api/public/messageDetail",{params});

//下单
export const addOrder = (params) => request.get("/api/order/addOrder",{params});

//订单详情
export const orderDetail = (params) => request.get("/api/order/detail",{params});

//支付
export const pay = (params) => request.get("/api/pay/pay",{params});

//热门搜索
export const hotSearch = (params) => request.get("/api/curriculum/hotSearch",{params});

//充值
export const recharge = (params) => request.get("/api/user/recharge",{params});

//充值订单支付
export const payRecharge = (params) => request.get("/api/pay/recharge",{params});

//检测是否充值成功
export const getUserRecharge = (params) => request.get("/api/user/getUserRecharge",{params});

//轮播图列表
export const bannerList = (params) => request.get("/api/public/bannerList",{params});

//pc查询刷脸结果
export const getShua = (params) => request.get("/api/user/getShua",{params});

//提现记录
export const userTiList = (params) => request.get("/api/user/userTiList",{params});

//底部导航栏友情连接
export const link = (params) => request.get("/api/public/link",{params});

//密码登录
export const passwordLogin = (params) => request.get("/api/user/passwordLogin",{params});

//设置密码
export const setPassword = (params) => request.get("/api/user/setPassword",{params});

//查看电子发票
export const getInvoiceFilePath = (params) => request.get("/api/user/getInvoiceFilePath",{params});

//删除发票抬头
export const deleteInvoiceTitle = (params) => request.get("/api/user/deleteInvoiceTitle",{params});

//视频播放时长记录
export const userCurriculumDetailStudyLast = (params) => request.get("/api/user/user_curriculum_detail_study_last",{params});








//获取商品详情 
// export const getProductDetail=(id)=>request.get(`/product/${id}`);
//方法二 在api文件里出来异步请求
// export const getCategory=async()=>{
// 	const res=await request.get(`/category/`);
// 	return res.data;
// };