<template>
  <nav>
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> | -->
    <!-- <router-link to="/logon">Logon</router-link> -->
  </nav>
  <div class="navigation_app_not_logged_in">
    <div class="logoBox_app">
      <div class="logoNavigation">
        <img class="logo_app" src="@/assets/logo.png" />

        <div class="navigation_app_left" v-if="isAlready">
          <div @click="onGo('/')" style="width:37px;" class="navigation_app_box" :class="navigationType == 1 ? 'navigation_app_box_check' : ''">
            <p>首页</p>
            <div></div>
          </div>
          <div @click="onInformationCenter" style="width:37px;" class="navigation_app_box" :class="navigationType == 2 ? 'navigation_app_box_check' : ''">
            <p>资讯</p>
            <div></div>
          </div>
          <div @click="onGo('curriculum')" style="width:37px;" class="navigation_app_box" :class="navigationType == 3 ? 'navigation_app_box_check' : ''">
            <p>课程</p>
            <div></div>
          </div>
          <div @click="onGo('examinationCenter')" style="width:75px;" class="navigation_app_box" :class="navigationType == 4 ? 'navigation_app_box_check' : ''">
            <p>考试中心</p>
            <div></div>
          </div>
          <!-- <div @click="onClose(true)" class="navigation_app_box" :class="navigationType == 6 ? 'navigation_app_box_check' : ''">
            <p>签到</p>
            <div></div>
          </div> -->
          <div @click="onGo('myStudy')" style="width:75px;" class="navigation_app_box" :class="navigationType == 5 ? 'navigation_app_box_check' : ''">
            <p>我的学习</p>
            <div></div>
          </div>
          <!-- <div @click="onGo('questionBank')" style="width:37px;" class="navigation_app_box" :class="navigationType == 6 ? 'navigation_app_box_check' : ''">
            <p>题库</p>
            <div></div>
          </div> -->
        </div>
      </div>

      <div class="logoBox_app_right_already" v-if="isAlready">
        <div v-if="isSearch" style="width: 47%;display: flex;justify-content: right;">
          <div class="searchBotton" @click="isSearch = false">
            <img src="@/assets/icon12.png" />
            <span>搜索</span>
          </div>
        </div>
        <div v-else class="navigation_app_right">
          <el-popover
            :width="385"
            popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;"
          >
            <template #reference>
              <input v-model="store.searchValues" placeholder="想找什么课程，我帮您找！" />
            </template>
            <template #default>
              <div class="popoverConentTitle">
                <img src="@/assets/icon33.png" />
                <div>热门搜索</div>
              </div>
              <div class="popoverConentList">
                <div @click="onSearch(item.title)" v-for="(item,index) in searchHistory" :key="index">{{item.title}}</div>
              </div>
            </template>
          </el-popover>

          <div @click="onSearch(store.searchValues)">
            <img src="@/assets/icon03.png" />
            <span>搜索</span>
          </div>
        </div>
        <div class="news" @click="onnews">
          <img src="@/assets/icon09.png" />
          <div v-if="isMessage"></div>
        </div>
        <div class="cart news" @click="onGo('cart')">
          <img src="@/assets/icon10.png" />
          <!-- <div></div> -->
        </div>
        <div class="signIn" @click="onClose(true)">
          <img src="@/assets/icon13.png" />
          <div>签到</div>
        </div>
        <div class="line"></div>
        <div class="dropdownBoxAll">
          <div class="el-dropdown-link">
            <img :src="(imgUrl+userInfor.avatar)" />
            <div>{{userInfor.nickname}}</div>
          </div>
          <div class="dropdownBox">
            <div class="dropdown" @click="onGo('myInvoice')">
              <div class="lines"></div>
              <div class="title">我的发票</div>
            </div>
            <div class="dropdown" @click="onGo('myPoints')">
              <div class="lines"></div>
              <div class="title">我的积分</div>
            </div>
            <div class="dropdown" @click="onGo('myAccount')">
              <div class="lines"></div>
              <div class="title">我的账户</div>
            </div>
            <div class="dropdown" @click="onGo('myCollection')">
              <div class="lines"></div>
              <div class="title">我的收藏</div>
            </div>
            <div class="dropdown" @click="onGo('myInvitation')">
              <div class="lines"></div>
              <div class="title">我的邀请</div>
            </div>
            <div class="dropdown" @click="onGo('myCourse')">
              <div class="lines"></div>
              <div class="title">我的课程</div>
            </div>
            <div class="dropdown" @click="onGo('userInformation')">
              <div class="lines"></div>
              <div class="title">个人信息</div>
            </div>
            <div class="dropdown" @click="onGo('myOrder')">
              <div class="lines"></div>
              <div class="title">我的订单</div>
            </div>
            <div class="dropdown" @click="onOut">
              <div class="lines"></div>
              <div class="title">退出登录</div>
            </div>
          </div>
        </div>
      </div>
      <div class="logoBox_app_right" v-else>
        <div class="logoBox_app_tishi">登录享受更多权益</div>
        <div class="logoBox_app_button" @click="onLogon">登录</div>
      </div>
    </div>
  </div>
  <div v-if="navigationType!=1" style="width:100%;height:81px;"></div>

  <div class="routerView"><router-view /></div>
  
  <div class="footerBox">
    <div class="linkBox"><img class="logo" src="@/assets/logo.png" /></div>
    
    <div class="linkBox">
      <div class="linkLeft">友情链接：</div>
      <div class="linkRight">
        <a :href="item.url" v-for="(item,index) in linkList" :key="index">
          <span>{{ item.name }}</span>
          <div class="line" v-if="index!=linkList.length-1"></div>
        </a>
        
        <!-- <span>住房和城乡建设部执业资格注册中心</span>
        <div></div>
        <span>北京市规划和自然资源委员会</span>
        <div></div>
        <span>北京市勘查设计协会</span>
        <div></div>
        <span>北京人事考试网</span> -->
      </div>
    </div>
    <div class="line"></div>
    <div class="phoneBox">
      <div class="phone">
        <div>继续教育：</div>
        <div class="tel">
          <span style="margin-right:40px;">010-88385997</span>
          <span>010-68353722</span>
        </div>
      </div>
      <div class="phone">
        <div>考前辅导：</div>
        <div class="tel">15801294613</div>
      </div>
      <div class="phone">
        <div>职业培训：</div>
        <div class="tel">13701001671</div>
      </div>
    </div>
    <div class="codeBox">
      <div class="code">
        <img src="@/assets/codeFW.png" />
        <div>设科教育服务号</div>
      </div>
      <div class="code">
        <img src="@/assets/codeGZ.png" />
        <div>设科教育公众号</div>
      </div>
      <div class="code">
        <img src="@/assets/codeXCX.jpg" />
        <div>设科教育小程序</div>
      </div>
    </div>
    <div class="FilingNo">
      <img src="@/assets/icon14.png" />
      <a href="https://beian.miit.gov.cn/" style="color: #fff;text-decoration:none">京ICP备11025578号-1 站长统计</a>
    </div>
  </div>
  <div class="logon" v-if="isLogon">
    <LogOn @onShut = 'onShut' @onAlready="onAlready" />
  </div>
  <div class="logon" v-if="isCalendar">
    <SignIn @onClose = 'onClose' />
  </div>
</template>

<script>
import { defineComponent,onMounted,reactive,toRefs,watch } from 'vue'
import LogOn from './components/logOn.vue'
import SignIn from './components/signIn.vue'
import {useRouter,useRoute} from 'vue-router'
import { useStore } from '@/store/index'
import { storeToRefs } from 'pinia'
import { ElMessage } from 'element-plus'
import { ArrowDown } from '@element-plus/icons-vue'
import { userDetail,messageList,hotSearch,link } from '@/request/api'


export default defineComponent({
  components:{LogOn,SignIn},
  setup() {
    const store = useStore();
    const { imgUrl } = storeToRefs(store);
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      isAlready:false,  //判断是否登录
      navigationType:1,
      isLogon:false,
      isCalendar:false,
      searchValue:'',
      searchValues:'',
      searchHistory:[],
      isSearch:true,
      userInfor:{},
      isMessage:false,
      linkList:[]
    })
    watch(() => router.currentRoute.value.path,(newValue, oldValue) => {
      // console.log("watch=5", newValue);
      state.navigationType = 0;
      if (newValue == '/') return state.navigationType = 1;
      if(newValue == '/informationCenter') return state.navigationType = 2;
      if(newValue == '/curriculum') return state.navigationType = 3;
      if(newValue == '/examinationCenter') return state.navigationType = 4;
      if(newValue == '/myStudy') return state.navigationType = 5;
      if(newValue == '/questionBank') return state.navigationType = 6;
    },{ immediate: true });
    onMounted(()=>{
      if (localStorage.getItem('token')) {
        state.isAlready = true;
        userDetail({api_token:localStorage.getItem('token')}).then(res=>{
            console.log(res.data.data.nickname,'个人信息')
            state.userInfor = res.data.data;
        })
      }else{
        router.push('/');
      }
      onMessageList();
      onHotSearch();
      link().then(res=>{
        console.log(res,'底部导航栏');
        state.linkList = res.data.data;
      })
    })
    const onHotSearch = () => {
      hotSearch({
        pageSize:999
      }).then(res=>{
        state.searchHistory = res.data.data;
      })
    }
    const onMessageList = () => {
		if(localStorage.getItem('token')){
			messageList({
			  api_token:localStorage.getItem('token'),
			  pageSize:9999
			}).then(res=>{
			  // console.log(res,'系统消息');
			  res.data.data.forEach(ele=>{
			    if(ele.user_message[0].status == 0){
			      state.isMessage = true;
			    }
			  })
			})
		}
     
    }
    const onOut = () => {
      router.push('/');
      localStorage.clear();
      setTimeout(()=>{
        location.reload()
      },500)
    }
    const handleCommand = (command) => {
      ElMessage(`click on item ${command}`)
    }
    const onGo = (type) => {
      router.push(type);
    }
    const onnews = () => {
        router.push('news');
    }
    const onInformationCenter = () => {
        router.push('informationCenter');
    }
    const onLogon = () => {
      state.isLogon = true;
    }
    const onAlready = () => {
      state.isAlready = true;
      state.userInfor = JSON.parse(localStorage.getItem('userInfor'));
      console.log(state.userInfor,'个人信息');
    }
    const onClose = (item) => {
      state.isCalendar = item;
    }
    const onShut = (item) => {
      state.isLogon = item;
      userDetail({api_token:localStorage.getItem('token')}).then(res=>{
          console.log(res.data.data.nickname,'个人信息')
		  if(res.data.data){
			  state.userInfor = res.data.data;
		  }
      })
    }
    const onSearch = (item) =>{
      // console.log(route.path);
      store.searchValue = item;
      // store.searchValues = item;
      // console.log(store.searchValue);
      router.push('curriculum?searchValue='+item);
    }
    return {
      onLogon,
      onShut,
      onSearch,
      store,
      onClose,
      ...toRefs(state),
      imgUrl,
      handleCommand,
      onnews,
      onInformationCenter,
      onGo,
      onAlready,
      onOut,
      link
    }
  }
})
</script>


<style lang="scss">
 @import '@/App.scss'
</style>
