<template>
    <div class="carouselBox">
        <el-carousel height="540px" arrow="always" indicator-position="none">
            <el-carousel-item v-for="item in bannerListArr" :key="item.id">
                <img class="carouselImg" :src="imgUrl+item.pc_image" @click="item.url?onGo('courseDetails?id='+item.url):''" />
            </el-carousel-item>
        </el-carousel>
        <div class="typeBox">
            <div @click="onGo(item.path)" v-for="(item,index) in typeList.typeArr" :key="index">{{item.name}}</div>
        </div>
    </div>
    <div class="goodsBoxAll">
        <div class="modularTitleBox">
            <div>最新课程</div>
            <!-- <p>从最初的提供网站建设扩大到如今涵盖移动端应用软件开发、系统软件开发于一身的综合型外包服务公司；设科…从最初的提供网站建设扩大到如今涵盖移动端应用软件开发、系统软件开发于一身的综合型外包服务公司；设科…从最初的提供网站建设扩大到如今涵盖移动端应用软件开发、系统软件开发于一身的综合型外包服务公司；设科…</p> -->
        </div>
        <div v-if="curriculumListsOne" class="goodsBox">
            <div class="goodsList">
                <div v-if="curriculumListsOne" class="goods1" :style="`background-image: url(${imgUrl}${curriculumListsOne.cover});`" style="background-size: 100% 100%;">
                    <div class="di">
                        <div class="name">主讲老师：<span v-show="index < 2" v-for="(item,index) in curriculumListsOne.teacher" :key="index">{{item.name}}&nbsp;</span></div>
                        <div class="title">{{curriculumListsOne.title}}</div>
                        <div class="buttonBox">
                            <div class="button" @click="onCourseDetails(curriculumListsOne.id)">学习</div>
                            <div class="thumbsup">
                                <div>
                                    <img @click="onCollect(curriculumListsOne,2)" v-if="curriculumListsOne.like" src="@/assets/icon69.png" />
                                    <img @click="onCollect(curriculumListsOne,2)" v-else src="@/assets/icon18.png" />
                                    <span>{{curriculumListsOne.like_num}}</span>
                                </div>
                                <div>
                                    <img @click="onCollect(curriculumListsOne,1)" v-if="curriculumListsOne.collection" src="@/assets/icon68.png" />
                                    <img @click="onCollect(curriculumListsOne,1)" v-else src="@/assets/icon70.png" />
                                    <span>{{curriculumListsOne.collection_num}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="curriculumListsTwo" class="goods1 goods2"  :style="`background-image: url(${imgUrl}${curriculumListsTwo.cover});`" style="background-size: 100% 100%;">
                    <div class="di">
                        <div class="name">主讲老师：<span v-show="index < 2" v-for="(item,index) in curriculumListsTwo.teacher" :key="index">{{item.name}}&nbsp;</span></div>
                        <div class="title">{{curriculumListsTwo.title}}</div>
                        <div class="buttonBox">
                            <div class="button" @click="onCourseDetails(curriculumListsTwo.id)">学习</div>
                            <div class="thumbsup">
                                <div>
                                    <img @click="onCollect(curriculumListsTwo,2)" v-if="curriculumListsTwo.like" src="@/assets/icon69.png" />
                                    <img @click="onCollect(curriculumListsTwo,2)" v-else src="@/assets/icon18.png" />
                                    <span>{{curriculumListsTwo.like_num}}</span>
                                </div>
                                <div>
                                    <img @click="onCollect(curriculumListsTwo,1)" v-if="curriculumListsTwo.collection" src="@/assets/icon68.png" />
                                    <img @click="onCollect(curriculumListsTwo,1)" v-else src="@/assets/icon70.png" />
                                    <span>{{curriculumListsTwo.collection_num}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="goods1" v-for="(item,index) in curriculumLists" :key="index" :style="`background-image: url(${imgUrl}${item.cover});`" style="background-size: 100% 100%;">
                    <div class="di">
                        <div class="name">主讲老师：<span v-show="indexs < 2" v-for="(items,indexs) in item.teacher" :key="indexs">{{items.name}}&nbsp;</span></div>
                        <div class="title">{{item.title}}</div>
                        <div class="buttonBox">
                            <div class="button" @click="onCourseDetails(item.id)">学习</div>
                            <div class="thumbsup">
                                <div>
                                    <img @click="onCollect(item,2)" v-if="item.like" src="@/assets/icon69.png" />
                                    <img @click="onCollect(item,2)" v-else src="@/assets/icon18.png" />
                                    <span>{{item.like_num}}</span>
                                </div>
                                <div>
                                    <img @click="onCollect(item,1)" v-if="item.collection" src="@/assets/icon68.png" />
                                    <img @click="onCollect(item,1)" v-else src="@/assets/icon70.png" />
                                    <span>{{item.collection_num}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="goods1" style="height:1px;"></div>
                <div class="goods1" style="height:1px;"></div>
            </div>
            <div class="pageUnder" @click="onGo('curriculum')">查看更多 ></div>
        </div>
        <empty v-else description="暂无最新课程" />
    </div>
    <div style="background:#ffffff;width: 100%;padding: 60px 0 30px 0;">
        <div class="modularTitleBox" style="margin-top:30px;">
            <div>推荐课程</div>
            <!-- <p>从最初的提供网站建设扩大到如今涵盖移动端应用软件开发、系统软件开发于一身的综合型外包服务公司；设科…从最初的提供网站建设扩大到如今涵盖移动端应用软件开发、系统软件开发于一身的综合型外包服务公司；设科…从最初的提供网站建设扩大到如今涵盖移动端应用软件开发、系统软件开发于一身的综合型外包服务公司；设科…</p> -->
        </div>
        <div v-if="recommendCurriculumListOne" class="recommendBox">
            <div class="recommendLeft" :style="`background-image: url(${imgUrl}${recommendCurriculumListOne.cover});`" style="background-size: 100% 100%;">
                <div class="dis">
                    <div class="title">{{recommendCurriculumListOne.title}}</div>
                    <div class="name">
                        <div class="nametxt">主讲老师：<span v-for="(items,indexs) in recommendCurriculumListOne.teacher" :key="indexs">{{items.name}}&nbsp;</span></div>
                        <div class="thumbsup">
                            <div>
                                <img @click="onCollect(recommendCurriculumListOne,2)" v-if="recommendCurriculumListOne.like" src="@/assets/icon69.png" />
                                <img @click="onCollect(recommendCurriculumListOne,2)" v-else src="@/assets/icon18.png" />
                                <span>{{recommendCurriculumListOne.like_num}}</span>
                            </div>
                            <div>
                                <img @click="onCollect(recommendCurriculumListOne,1)" v-if="recommendCurriculumListOne.collection" src="@/assets/icon68.png" />
                                <img @click="onCollect(recommendCurriculumListOne,1)" v-else src="@/assets/icon70.png" />
                                <span>{{recommendCurriculumListOne.collection_num}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="priceBox">
                        <div class="price"><span>¥</span>{{recommendCurriculumListOne.price}}</div>
                        <div class="button" @click="onCourseDetails(recommendCurriculumListOne.id)">学习</div>
                    </div>
                </div>
            </div>
            <div class="recommendRight">
                <div v-for="(item,index) in recommendCurriculumList" :key="index">
                    <div class="goods" style="" :style="index>1?'margin-top: 15px;':''">
                        <div class="title">{{item.title}}</div>
                        <p>主讲老师：<span v-show="indexs < 2" v-for="(items,indexs) in item.teacher" :key="indexs">{{items.name}}&nbsp;</span></p>
                        <div class="priceBox">
                            <div class="price"><span>¥</span>{{item.price}}</div>
                            <div class="thumbsup">
                                <div>
                                    <img @click="onCollect(item,2)" v-if="item.like" src="@/assets/icon19.png" />
                                    <img @click="onCollect(item,2)" v-else src="@/assets/icon71.png" />
                                    <span>{{item.like_num}}</span>
                                </div>
                                <div>
                                    <img @click="onCollect(item,1)" v-if="item.collection" src="@/assets/icon34.png" />
                                    <img @click="onCollect(item,1)" v-else src="@/assets/icon04.png" />
                                    <span>{{item.collection_num}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="xuexi" @click="onCourseDetails(item.id)">学习</div>
                        <img class="icon" v-if="(index==0||index==1)" src="@/assets/icon72.png" />
                    </div>
                </div>
            </div>
        </div>
        <empty v-else description="暂无推荐课程" />
        <div class="pageUnder" style="width:1200px;'" @click="onGo('curriculum')">查看更多 ></div>
    </div>
    
    <div style="width:100%;height:55px;background:#F6F7F8;"></div>
    <div class="scrabbleDoughArr">
        <div class="scrabbleDough">
            <div class="modularTitleBox">
                <div>限时秒杀</div>
                <!-- <p>从最初的提供网站建设扩大到如今涵盖移动端应用软件开发、系统软件开发于一身的综合型外包服务公司；设科…从最初的提供网站建设扩大到如今涵盖移动端应用软件开发、系统软件开发于一身的综合型外包服务公司；设科…从最初的提供网站建设扩大到如今涵盖移动端应用软件开发、系统软件开发于一身的综合型外包服务公司；设科…</p> -->
            </div>
            <div class="timeBox">
                <div @click="onSeckillTime(item)" :class="seckillItemId==item.id?'check':''" :style="seckillItemId==item.id?'border-left:1px solid #fff8f4':''" v-for="(item,index) in seckillLists" :key="index">{{item.start_time}} {{item.itemType==1?'抢购中':item.itemType==2?'待开始':'已结束'}}</div>
            </div>
            <div class="goodsList">
                <div class="goods" v-for="(item,index) in seckillDetailList" :key="index">
                    <img :src="imgUrl + item.curriculum.cover" />
                    <div class="inforBox">
                        <div class="title">{{item.curriculum.title}}</div>
                        <p>主讲老师：<span v-for="(items,indexs) in item.curriculum.teacher" :key="indexs">{{items.name}}&nbsp;</span></p>
                        <div class="priceBox">
                            <div class="price"><span>¥</span>{{item.curriculum.price}}<div>¥{{item.curriculum.old_price}}</div></div>
                            <div v-if="(itemType==3)" class="is" style="cursor: pointer;">已结束</div>
                            <div v-else-if="(itemType==2)" class="is" style="cursor: pointer;">即将开抢</div>
                            <div v-else-if="(itemType==1)" @click="onCourseDetails(item.curriculum_id)" class="iss" style="cursor: pointer;">马上抢</div>
                        </div>
                    </div>
                </div>
                <empty v-if="seckillDetailList.length==0" description="暂无秒杀商品" />
            </div>
        </div>
    </div>
    <div style="width:100%;height:55px;background:#F6F7F8;"></div>
    <div style="background-color: #ffffff;">
        <div class="modularTitleBox" style="padding-top: 60px;">
            <div>拼团活动</div>
            <!-- <p>从最初的提供网站建设扩大到如今涵盖移动端应用软件开发、系统软件开发于一身的综合型外包服务公司；设科…从最初的提供网站建设扩大到如今涵盖移动端应用软件开发、系统软件开发于一身的综合型外包服务公司；设科…从最初的提供网站建设扩大到如今涵盖移动端应用软件开发、系统软件开发于一身的综合型外包服务公司；设科…</p> -->
        </div>
        <div class="seckillBox">
            <div class="goodsSeckill" :class="seckillId == item.id ? 'goodsSeckillTow':''" v-for="(item,index) in groupCurriculumLists" :key="index">
                <div class="goods" @click="onSeckill(item.id)">
                    <img :src="(imgUrl+item.curriculum.cover)" />
                    <p>{{item.curriculum.title}}</p>
                    <div>{{item.success_participation_num}}人已团</div>
                </div>
                <div class="seckill">
                    <div class="number">拼团<span>{{item.number_people}}人团</span></div>
                    <div class="buttonBox">
                        <div class="title">距结束仅剩</div>
                        <div class="title" style="font-size: 20px;font-weight: 700;"><countDown :timeStamp="item.end_time" /></div>
                        <div class="line"></div>
                        <div class="princ"><span>¥</span>{{item.price}}<div>¥{{item.curriculum.price}}</div></div>
                        <div class="button" @click="onCourseDetails(item.curriculum_id)">我要拼团</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="pageUnder" style="width:1200px;'">查看更多 ></div> -->
    <div style="width:100%;height:55px;background:#F6F7F8;"></div>
    <div class="aboutBox">
        <div class="aboutBoxLeft">
            <div class="titleBox">
                <div class="title">关于我们</div>
                <!-- <div class="txt">从最初的提供网站建设扩大到如今涵盖移动端应用软件开发、系统软件开发于一身的综合型外包服务公司；设科…</div> -->
                <div class="line"></div>
                <div class="name">
                    <p></p>
                    <div>{{settingObj.companyName}}</div>
                </div>
            </div>
        </div>
        <div class="aboutBoxRight">
            <div class="name">{{settingObj.companyName}}</div>
            <div class="zhu">我们珍惜每一次合作，与众多客户共同发展。</div>
            <div class="txt" v-html="settingObj.guanyuwomen"></div>
            <div class="gengduo" @click="onAbout">查看更多 ></div>
        </div>
    </div>
    <div style="width:100%;height:55px;background:#F6F7F8;"></div>
    <div class="consultingService">
        <div class="modularTitleBox" style="margin-top: 60px;">
            <div>资讯中心</div>
            <!-- <p>从最初的提供网站建设扩大到如今涵盖移动端应用软件开发、系统软件开发于一身的综合型外包服务公司；设科…从最初的提供网站建设扩大到如今涵盖移动端应用软件开发、系统软件开发于一身的综合型外包服务公司；设科…从最初的提供网站建设扩大到如今涵盖移动端应用软件开发、系统软件开发于一身的综合型外包服务公司；设科…</p> -->
        </div>
        <div class="consultingService_box">
            <div v-if="newsListOne" class="consultingService_left" @click="onGo('infoDetails?id='+newsListOne.id)">
                <div class="time">{{newsListOne.created_at||''}}</div>
                <div class="title">{{newsListOne.title}}</div>
                <div class="txt" v-html="newsListOne.content"></div>
                <img :src="imgUrl+newsListOne.img" />
            </div>
            <div class="consultingService_right" v-if="newsLists.lenght">
                <div class="consultingService_right_box" @click="onGo('infoDetails?id='+item.id)" v-for="(item,index) in newsLists" :key="index">
                    <div class="time">{{item.created_at||''}}</div>
                    <div class="titleBox">
                        <p></p>
                        <div>{{item.title}}</div>
                    </div>
                    <div class="txt" v-html="item.content"></div>
                </div>
            </div>
        </div>
        <div @click="onGo('informationCenter')" class="pageUnder" style="width:1200px;'">查看更多 ></div>
    </div>
    <div style="width:100%;height:55px;background:#F6F7F8;"></div>
</template>

<script>
import { defineComponent,onMounted,reactive,ref,toRefs } from 'vue'
import {useRouter} from 'vue-router'
import goods from '@/components/goods.vue'
import countDown from '@/components/countDown.vue'
import empty from '@/components/empty.vue'
import { storeToRefs } from 'pinia'
import { useStore } from '@/store/index'
import { ElMessage } from 'element-plus'
import { 
    curriculumList,
    collect,
    collectCancel,
    likeCancel,
    like,
    setting,
    newsList,
    seckillList,
    seckillDetailLis,
    groupCurriculumList,
    bannerList
} from '@/request/api'

 
export default defineComponent({
    components:{ goods,countDown,empty },
    setup() {
        const store = useStore();
        const { imgUrl } = storeToRefs(store);
        const router = useRouter();
        // let curriculumLists = reactive([])
        const data = reactive({
            seckillId:0,
            curriculumListsOne:'',
            curriculumListsTwo:'',
            curriculumLists:[],
            recommendCurriculumList:[],
            recommendCurriculumListOne:'',
            settingObj:{},
            newsLists:[],
            newsListOne:{},
            seckillLists:[],
            seckillItemId:0,
            itemType:1,
            timeDifference:0,
            seckillDetailList:[],
            groupCurriculumLists:[],
            bannerListArr:[]
        })
        let typeList = reactive({
            typeArr:[
                {
                    id:1,
                    name:'课程中心',
                    path:'curriculum'
                },
                // {
                //     id:2,
                //     name:'题库中心',
                //     path:'questionBank'
                // },
                {
                    id:3,
                    name:'积分兑换',
                    path:'myPoints'
                },
                {
                    id:4,
                    name:'学习中心',
                    path:'myStudy'
                },
                {
                    id:5,
                    name:'资讯中心',
                    path:'informationCenter'
                }
            ],
        })
        
        onMounted(()=>{
            onCurriculumList();
            onSetting();
            // console.log(localStorage.getItem('userInfor'));
            
        })
        const onSeckillTime = (item) => {
            data.seckillItemId = item.id;
            data.seckillDetailList = [];
            data.itemType = item.itemType;
            seckillList().then(res=>{
                
                var timer = new Date().getTime();
                res.data.data.forEach(ele=>{
                    var startTime = new Date(`${ele.start_day} ${ele.start_time}`).getTime();
                    var endTime = new Date(`${ele.end_day} ${ele.end_time}`).getTime();
                    if(startTime < timer && endTime > timer){
                        let day = new Date().toISOString().slice(0, 10); //当前日期
                        let startDayTime = new Date(`${day} ${ele.start_time}`).getTime(); //当天秒杀开始时间
                        let endDayTime = new Date(`${day} ${ele.end_time}`).getTime(); //当天秒杀结束时间
            
                        if(startDayTime < timer && endDayTime > timer){
                            // data.seckillItemId = ele.id;
                            ele.itemType = 1; //抢购中
                            data.timeDifference = endDayTime - startDayTime;
                        }else if(startDayTime > timer){
                            ele.itemType = 2;  //待开始
                        }else if(endDayTime < timer){
                            ele.itemType = 3;  //已结束
                        }
                    }else if(startTime > timer){
                        ele.itemType = 2;  //待开始
                        data.itemType = 2;
                    }else if(endTime < timer){
                        ele.itemType = 3;  //已结束
                        data.itemType = 3;
                    }
                })
                data.seckillLists = res.data.data;
                onSeckillDetailLis(data.seckillItemId)
            })
        }
        const onSetting = () =>{
            setting({alias:'guanyuwomen'}).then(res=>{
                // console.log(res.data.data.guanyuwomen,'公共配置');
                data.settingObj.guanyuwomen = res.data.data.guanyuwomen;
            })
            setting({alias:'companyName'}).then(res=>{
                // console.log(res.data.data.companyName,'公共配置');
                data.settingObj.companyName = res.data.data.companyName;
            })
            newsList({
                page:1,
                pageSize:5
            }).then(res=>{
                data.newsLists = res.data.data;
                data.newsListOne = data.newsLists[0];
                data.newsLists.splice(0,1);
                // console.log(data.newsLists,data.newsListOne);
            })
            // 秒杀时间列表
            seckillList().then(res=>{
                
                var timer = new Date().getTime();
                res.data.data.forEach(ele=>{
                    var startTime = new Date(`${ele.start_day} ${ele.start_time}`).getTime();
                    var endTime = new Date(`${ele.end_day} ${ele.end_time}`).getTime();
                    if(startTime < timer && endTime > timer){
                        let day = new Date().toISOString().slice(0, 10); //当前日期
                        let startDayTime = new Date(`${day} ${ele.start_time}`).getTime(); //当天秒杀开始时间
                        let endDayTime = new Date(`${day} ${ele.end_time}`).getTime(); //当天秒杀结束时间
            
                        if(startDayTime < timer && endDayTime > timer){
                            data.seckillItemId = ele.id;
                            ele.itemType = 1; //抢购中
                            data.timeDifference = endDayTime - startDayTime;
                        }else if(startDayTime > timer){
                            ele.itemType = 2;  //待开始
                        }else if(endDayTime < timer){
                            ele.itemType = 3;  //已结束
                        }
                    }else if(startTime > timer){
                        ele.itemType = 2;  //待开始
                        data.itemType = 2;
                    }else if(endTime < timer){
                        ele.itemType = 3;  //已结束
                        data.itemType = 3;
                    }
                })
                data.seckillLists = res.data.data;
                if (data.seckillItemId != 0) onSeckillDetailLis(data.seckillItemId);
            })
            groupCurriculumList({
                page:1,
                pageSize:999
            }).then(res=>{
                data.groupCurriculumLists = res.data.data;
				if(data.groupCurriculumLists[0]){
					data.seckillId = data.groupCurriculumLists[0].id
				}
                
                // console.log(res,'拼团商品列表');
            })
            bannerList({type:2}).then(res=>{
                console.log(res,'轮播图');
                data.bannerListArr = res.data.data;
            })
        }
        const onSeckillDetailLis = (id) => {
            seckillDetailLis({
                seckill_id:id,
                pageSize:9999,
                page:1
            }).then(res=>{
                // console.log(res,'限时秒杀课程');
                data.seckillDetailList = res.data.data;
            })
        }
        const onGo = (url) => {
            if(!localStorage.getItem('token')) return ElMessage('请先登录');
            router.push(url)
        }
        const onSeckill = (id) => {
            if(data.seckillId == id){
                // console.log('查看拼团商品详情')
            }
            data.seckillId = id;
        }
        const onCourseDetails = (id) => {
            router.push('courseDetails?id='+id);
        }
        // 添加或取消收藏
        const onCollect = (item,type) => {
            if (type == 1) {
                if(!item.collection){
                    collect({
                        parameter_id:item.id,
                        type:1,
                        api_token:localStorage.getItem('token')
                    }).then(res=>{
                        // console.log(res,'添加收藏');
                        if(res.data.code == 0){
                            onCurriculumList();
                        }
                    })
                }else{
                    collectCancel({
                        id:item.collection.id,
                        api_token:localStorage.getItem('token')
                    }).then(res=>{
                        // console.log(res,'取消收藏');
                        if(res.data.code == 0){
                            onCurriculumList();
                        }
                    })
                }
                
            }else{
                if(!item.like){
                    like({
                        parameter_id:item.id,
                        type:1,
                        api_token:localStorage.getItem('token')
                    }).then(res=>{
                        // console.log(res,'添加收藏');
                        if(res.data.code == 0){
                            onCurriculumList();
                        }
                    })
                }else{
                    likeCancel({
                        id:item.like.id,
                        api_token:localStorage.getItem('token')
                    }).then(res=>{
                        // console.log(res,'取消收藏');
                        if(res.data.code == 0){
                            onCurriculumList();
                        }
                    })
                }
            }
            
        }
        const onAbout = () => {
            router.push('about');
        }
        const onCurriculumList = () => {
            curriculumList({is_new:1,pageSize:5,page:1,api_token:localStorage.getItem('token')}).then(res=>{
                // console.log(res,'最新课程');
                data.curriculumLists = res.data.data;
                data.curriculumListsOne = data.curriculumLists[0];
                data.curriculumListsTwo = data.curriculumLists[1];
                data.curriculumLists.splice(0, 2); 
            })
            curriculumList({is_tui:1,pageSize:5,page:1,api_token:localStorage.getItem('token')}).then(res=>{
                data.recommendCurriculumList = res.data.data;
                data.recommendCurriculumListOne = data.recommendCurriculumList[0];
                data.recommendCurriculumList.splice(0, 1); 
                // console.log(res,'推荐课程');
            })
        }
        return {
            ...toRefs(data),
            typeList,
            // curriculumLists,
            imgUrl,
            onCourseDetails,
            onAbout,
            onSeckill,
            onCurriculumList,
            onCollect,
            onGo,
            onSeckillDetailLis,
            onSeckillTime
        }
    },
})
</script>


<style lang="scss" scoped>

.carouselImg{
    width: 100%;
    height: 100%;
}
.carouselBox{
    width: 100%;
    height: 540px;
    position: relative;
    .typeBox{
        width: 1200px;
        height: 126px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 30px;
        left: calc(50% - 600px);
        div{
            width: 228px;
            height: 126px;
            border-radius: 8px;
            font-size: 22px;
            font-weight: 700;
            text-align: center;
            color: #000;
            letter-spacing: 1.1px;
            line-height: 126px;
            background-size: 100%;
            cursor: pointer;
            background: rgba($color: #fff, $alpha:1);
        }
        div:hover{
            background: #FC6245;
			color: #fff;
        }
    }
}
.recommendBox{
    width: 1200px;
    padding: 60px 0 0px 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .recommendLeft{
        width: 368px;
        height: 437px;
        background: rgba(0,0,0,0.00);
        border-radius: 2px;
        position: relative;
        .dis{
            width: 344px;
            height: 131px;
            background: rgba($color: #000000, $alpha: 0.8);
            border-radius: 2px;
            padding: 12px;
            position: absolute;
            bottom: 0;
            left: 0;
            .title{
                width: 100%;
                height: 50px;
                font-size: 18px;
                font-family: PingFang SC, PingFang SC-Regular;
                font-weight: 400;
                text-align: left;
                color: #ffffff;
                letter-spacing: -0.18px;
                overflow:hidden;  //超出文本隐藏
                text-overflow:ellipsis;  ///超出部分省略号显示
                display:-webkit-box;  //弹性盒模型
                -webkit-box-orient:vertical;  //上下垂直
                -webkit-line-clamp:2;  //自定义行数
            }
            .name{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 15px 0;
                .nametxt{
                    height: 22px;
                    font-size: 16px;
                    font-weight: 400;
                    text-align: left;
                    color: #ffffff;
                }
                .thumbsup{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    div{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: 15px;
                        img{
                            width: 20px;
                            height: 20px;
                        }
                        span{
                            height: 20px;
                            font-size: 14px;
                            font-family: PingFang SC, PingFang SC-Regular;
                            font-weight: 400;
                            text-align: left;
                            color: #ffffff;
                        }
                    }
                }
            }
            .priceBox{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .price{
                    height: 29px;
                    font-size: 20px;
                    font-weight: 700;
                    text-align: left;
                    color: #ffffff;
                    span{
                        font-size: 15px;
                    }
                }
                .button{
                    width: 96px;
                    height: 27px;
                    background: linear-gradient(224deg,#ff8c29 13%, #f9634c 95%);
                    border-radius: 3px;
                    font-size: 16px;
                    font-weight: 500;
                    text-align: center;
                    color: #ffffff;
                    line-height: 27px;
                }
            }
        }
    }
    .recommendRight{
        width: 820px;
        height: 437px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        .goods{
            width: 380px;
            height: 135px;
            opacity: 0.89;
            background: rgba(0,0,0,0.00);
            border-radius: 2px;
            padding: 38px 15px;
            position: relative;
			background:url(@/assets/back16.png) 100% 100%;
            
            .title{
                width: 100%;
                height: 50px;
                font-size: 18px;
                font-family: PingFang SC, PingFang SC-Regular;
                font-weight: 400;
                text-align: left;
                color: #333333;
                letter-spacing: -0.18px;
                overflow:hidden;  //超出文本隐藏
                text-overflow:ellipsis;  ///超出部分省略号显示
                display:-webkit-box;  //弹性盒模型
                -webkit-box-orient:vertical;  //上下垂直
                -webkit-line-clamp:2;  //自定义行数
            }
            p{
                height: 22px;
                font-size: 16px;
                font-weight: 400;
                text-align: left;
                color: #999999;
            }
            .priceBox{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .price{
                    height: 29px;
                    font-size: 20px;
                    font-weight: 700;
                    text-align: left;
                    color: #fc6245;
                    span{
                        font-size: 15px;
                    }
                }
                .thumbsup{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    div{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: 15px;
                        img{
                            width: 20px;
                            height: 20px;
                        }
                        span{
                            height: 20px;
                            font-size: 14px;
                            font-family: PingFang SC, PingFang SC-Regular;
                            font-weight: 400;
                            text-align: left;
                            color: #FB733D;
                        }
                    }
                }
            }
            .xuexi{
                width: 100%;
                height: 37px;
                background: linear-gradient(309deg,#ff8c29 9%, #f9634c);
                border-radius: 0px 0px 3px 3px;
                font-size: 16px;
                font-family: PingFang SC, PingFang SC-Medium;
                font-weight: 500;
                text-align: center;
                color: #ffffff;
                line-height: 37px;
            }
            .icon{
                width: 125px;
                height: 32px;
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
}
.modularTitleBox{
    width: 1200px;
    margin: 0 auto;
    border-bottom: 1px solid #666666;
    div{
        font-size: 30px;
        font-weight: 500;
        text-align: left;
        color: #333333;
        letter-spacing: 1.5px;
        margin-bottom: 15px;
    }
    p{
        width: 600px;
        height: 48px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
        line-height: 26px;
        overflow:hidden;  //超出文本隐藏
        text-overflow:ellipsis;  ///超出部分省略号显示
        display:-webkit-box;  //弹性盒模型
        -webkit-box-orient:vertical;  //上下垂直
        -webkit-line-clamp:2;  //自定义行数
    }
}
.goodsBoxAll{
    width: 100%;
    // height: 1117px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    background-image: url(@/assets/back07.png);
    background-size: 100% 100%;
    padding: 60px 0;
    .goodsBox{
        width: 1200px;
        margin: 0 auto;
        .goodsList{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            .goods2{
                width: 791px !important;
                background-size: 100%;
                .di{
                    width: 751px !important;
                }
            }
            .goods1{
                width: 380px;
                height: 378px;
                background: rgba(0,0,0,0.00);
                border-radius: 2px;
                box-shadow: 0px 3px 10px 0px #ebebeb; 
                position: relative;
                background-size: 100%;
                margin-top: 30px;
                .di{
                    width: 340px;
                    height: 112px;
                    background: #000000;
                    border-radius: 2px;
                    background-color: rgba($color: #000000, $alpha: 0.9);
                    padding: 12px 20px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    .name{
                        height: 22px;
                        font-size: 16px;
                        font-weight: 400;
                        text-align: left;
                        color: #ffffff;
                    }
                    .title{
                        width: 100%;
                        height: 28px;
                        font-size: 20px;
                        font-weight: 400;
                        text-align: left;
                        color: #ffffff;
                        letter-spacing: -0.2px;
                        overflow: hidden;  //超出文本隐藏
                        white-space: nowrap;  //不换行，只显示一行
                        text-overflow: ellipsis;  //超出部分省略号显示
                        margin: 12px 0 15px 0;
                    }
                    .buttonBox{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .button{
                            width: 96px;
                            height: 27px;
                            background: linear-gradient(224deg,#ff8c29 13%, #f9634c 95%);
                            border-radius: 3px;
                            font-size: 16px;
                            font-family: PingFang SC, PingFang SC-Medium;
                            font-weight: 500;
                            text-align: center;
                            color: #ffffff;
                            line-height: 27px;
                            cursor: pointer;
                        }
                        .thumbsup{
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            div{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-left: 15px;
                                img{
                                    width: 20px;
                                    height: 20px;
                                }
                                span{
                                    height: 20px;
                                    font-size: 14px;
                                    font-family: PingFang SC, PingFang SC-Regular;
                                    font-weight: 400;
                                    text-align: left;
                                    color: #ffffff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.pageUnder{
    width: 100%;
    height: 30px;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #333333;
    letter-spacing: 0.8px;
    text-align: right;
    margin: 0 auto;
    margin-top: 17px;
    cursor: default;
}

.scrabbleDoughArr{
    width: 100%;
    height: 750px;
    background-image: url(../assets/back08.png);
    background-size: 100% 100%;
    
    .scrabbleDough{
        width: 1200px;
        // height: 762px;
        padding: 60px 0 30px 0;
        margin: 0 auto;
        
        .timeBox{
            width: 1200px;
            height: 66px;
            background: #fff8f4;
            border-radius: 3px;
            margin: 0 auto;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 30px;
            margin-bottom: 45px;
            // overflow-y: auto;
            div{
                // width: 262px;
                height: 28px;
                padding: 0 20px;
                font-size: 20px;
                font-weight: 500;
                text-align: center;
                color: #FA7258;
                letter-spacing: 1px;
                line-height: 28px;
                border-left: 1px solid #FC6245;
                overflow: auto;
                cursor: pointer;
            }
            .check{
                // width: 234px;
                height: 66px;
                color: #fff;
                background: #fa7258;
                line-height: 66px;
            }
        }
        .goodsList{
            width: 1200px;
            margin: 0 auto;
            overflow: auto;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-right: 16px;
            .goods{
                width: 288px;
                height: 374px;
                background: #ffffff;
                border-radius: 8px;
                box-shadow: 0px 3px 10px 0px #edf1fb; 
                margin-left: 15px;
                img{
                    width: 100%;
                    height: 212px;
                }
                .inforBox{
                    width: 268px;
                    height: 122px;
                    padding: 10px 10px;
                    .title{
                        width: 100%;
                        font-size: 18px;
                        font-weight: 400;
                        text-align: left;
                        color: #333333;
                        letter-spacing: -0.18px;
                        overflow:hidden;  //超出文本隐藏
                        text-overflow:ellipsis;  ///超出部分省略号显示
                        display:-webkit-box;  //弹性盒模型
                        -webkit-box-orient:vertical;  //上下垂直
                        -webkit-line-clamp:2;  //自定义行数
                    }
                    p{
                        height: 22px;
                        font-size: 16px;
                        font-weight: 400;
                        text-align: left;
                        color: #999999;
                        margin: 15px 0;
                    }
                    .priceBox{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .price{
                            height: 29px;
                            font-size: 20px;
                            font-weight: 700;
                            text-align: left;
                            color: #fc6245;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            span{
                                font-size: 15px;
                            }
                            div{
                                height: 17px;
                                font-size: 12px;
                                font-weight: 500;
                                text-decoration:  line-through;
                                text-align: left;
                                color: #999999;
                                line-height: 21px;
                                margin-left: 8px;
                            }
                        }
                        .is{
                            width: 64px;
                            height: 22px;
                            font-size: 16px;
                            font-family: PingFang SC, PingFang SC-Medium;
                            font-weight: 500;
                            text-align: center;
                            color: #999999;
                            line-height: 24px;
                        }
                        .iss{
                            width: 100px;
                            height: 27px;
                            background: linear-gradient(231deg,#ff8c29 11%, #f9634c 91%);
                            border-radius: 3px;
                            font-size: 16px;
                            font-weight: 500;
                            text-align: center;
                            color: #ffffff;
                            line-height: 27px;
                        }
                    }
                }
            }
            
        }
    }
}


.seckillBox{
    width: 1200px;
    margin: 0px auto;
    padding: 30px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
    .goodsSeckillTow{
        width: 440px !important;
        .seckill{
            width: 206px !important;
        }
    }
    .goodsSeckill:last-child{
        border-right: 1px solid #f7b892;
    }
    .goodsSeckill{
        width: 215px;
        height: 300px;
        // background: #ffffff;
        // border: 1px solid #f7b892;
        padding: 14px;
        display: flex;
        justify-content: space-between;
        align-content: center;
        background-image: url(@/assets/back18.png);
        background-size: 469px 328px;
        .goods{
            
            img{
                width: 215px;
                height: 166px;
                border-radius: 20px;
            }
            p{
                width: 198px;
                height: 56px;
                font-size: 20px;
                font-family: PingFang SC, PingFang SC-Medium;
                font-weight: 500;
                text-align: left;
                color: #333333;
                letter-spacing: -0.2px;
                overflow:hidden;  //超出文本隐藏
                text-overflow:ellipsis;  ///超出部分省略号显示
                display:-webkit-box;  //弹性盒模型
                -webkit-box-orient:vertical;  //上下垂直
                -webkit-line-clamp:2;  //自定义行数
            }
            div{
                width: 102px;
                height: 29px;
                background: #feded9;
                border-radius: 3px;
                font-size: 16px;
                font-weight: 400;
                text-align: center;
                color: #fc6245;
                line-height: 29px;
            }
        }
        .seckill{
            width: 0;
            overflow: hidden;
            .number{
                width: 204px;
                height: 71px;
                border: 1px solid #f7b892;
                border-radius: 8px;
                backdrop-filter: blur(27.3px);

                font-size: 32px;
                font-weight: 400;
                text-align: center;
                color: #fc6245;
                letter-spacing: -0.32px;
                line-height: 71px;
                display: flex;
                justify-content: center;
                align-items: center;
                span{
                    font-size: 16px;
                }
            }
            .buttonBox{
                width: 204px;
                height: 194px;
                border: 1px solid #f8b88f;
                border-radius: 8px;
                backdrop-filter: blur(27.3px);
                margin-top: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .title{
                    width: 100%;
                    height: 20px;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;
                    color: #f9634c;
                    letter-spacing: 0.14px;
                    margin-bottom: 6px;
                }
                .line{
                    width: 180px;
                    height: 1px;
                    background: #f8b88f;
                    margin: 15px auto;
                }
                .princ{
                    height: 29px;
                    font-size: 20px;
                    font-weight: 700;
                    text-align: center;
                    color: #fc6245;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 10px;
                    span{
                        font-size: 15px;
                    }
                    div{
                        height: 17px;
                        font-size: 12px;
                        font-weight: 500;
                        text-decoration:  line-through;
                        color: #999999;
                        line-height: 21px;
                        margin-left: 11px;
                    }
                }
                .button{
                    width: 180px;
                    height: 40px;
                    background: linear-gradient(215deg,#ff8c29 23%, #f9634c 80%);
                    border-radius: 3px;
                    margin: 0 auto;
                    font-size: 16px;
                    font-weight: 500;
                    text-align: center;
                    color: #ffffff;
                    line-height: 40px;
                    cursor: pointer;
                }
            }
        }
    }
}

.aboutBox{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .aboutBoxLeft{
        width: 69%;
        height: 963px;
        background-image: url(@/assets/back06.png);
        background-size: 100% 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .titleBox{
            width: calc(100% - 74px);
            // height: 179px;
            background: #ffffff;
            backdrop-filter: blur(30px);
            padding: 30px 37px;
            overflow: hidden;
            .title{
                width: 130px;
                height: 42px;
                font-size: 30px;
                font-family: PingFang SC, PingFang SC-Medium;
                font-weight: 500;
                text-align: left;
                color: #333333;
                letter-spacing: 1.5px;
                margin-bottom: 30px;
            }
            .txt{
                width: 600px;
                height: 48px;
                font-size: 16px;
                font-family: PingFang SC, PingFang SC-Regular;
                font-weight: 400;
                text-align: left;
                color: #333333;
                line-height: 26px;
                overflow:hidden;  //超出文本隐藏
                text-overflow:ellipsis;  ///超出部分省略号显示
                display:-webkit-box;  //弹性盒模型
                -webkit-box-orient:vertical;  //上下垂直
                -webkit-line-clamp:2;  //自定义行数
                margin: 15px 0;
            }
            .line{
                width: 1200px;
                height: 1px;
                background: #333333;
            }
            .name{
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-top: 30px;
                p{
                    width: 7px;
                    height: 7px;
                    background: #333333;
                    border-radius: 50%;
                    margin: 0;
                }
                div{
                    height: 28px;
                    font-size: 20px;
                    font-family: PingFang SC, PingFang SC-Medium;
                    font-weight: 500;
                    text-align: left;
                    color: #333333;
                    letter-spacing: 1px;
                    margin-left: 15px;
                }
            }
        }
    }
    .aboutBoxRight{
        width: 25%;
        height: 843px;
        padding: 60px 3%;
        .name{
            height: 33px;
            font-size: 24px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            text-align: left;
            color: #333333;
            letter-spacing: 1.2px;
            padding-bottom: 15px;
            border-bottom: 1px solid #333333;
        }
        .zhu{
            // width: 419px;
            height: 28px;
            font-size: 20px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            text-align: left;
            color: #333333;
            letter-spacing: 1px;
            margin-top: 17px;
        }
        .txt{
            width: 100%;
            // height: 556px;
            font-size: 15px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: left;
            color: #666666;
            line-height: 29px;
            margin-top: 30px;
            word-break:break-all;
            display:-webkit-box;
            -webkit-line-clamp:19; //这里写的数字是几就是超过几行剩余的显示省略号
            -webkit-box-orient:vertical;
            overflow:hidden;
        }
        .gengduo{
            width: 181px;
            height: 22px;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            text-align: right;
            color: #333333;
            letter-spacing: 0.8px;
            border-top: 1px solid #333333;
            padding: 17px 0;
            margin-top: 125px;
            cursor: default;
        }
    }
}
.consultingService{
    width: 100%;
    padding: 60px 0;
    background-color: #ffffff;
    
    .consultingService_box{
        width: 1200px;
        margin: 30px auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .consultingService_left{
            width: 640px;
            height: 573px;
            background: #F6F7F8;
            border-radius: 8px;
            padding: 30px 20px;
            img{
                width: 640px;
                height: 357px;
                border-radius: 8px;
                margin-top: 30px;
            }
            .time{
                height: 30px;
                font-size: 22px;
                font-weight: 500;
                text-align: left;
                color: #666666;
                letter-spacing: 1.1px;
            }
            .title{
                font-size: 20px;
                font-weight: 500;
                text-align: left;
                color: #333333;
                letter-spacing: 1px;
                margin: 15px 0;
            }
            .txt{
                width: 640px;
                font-size: 15px;
                font-weight: 400;
                text-align: left;
                color: #666666;
                line-height: 26px;
            }
        }
        .consultingService_right{
            width: 449px;
            height: 633px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            border-bottom: 1px solid #E8E8E8;
            .consultingService_right_box:hover{
                box-shadow: 0px 3px 10px 0px #e0e8fc; 
            }
            .consultingService_right_box{
                width: 100%;
                height: 114px;
                background: #ffffff;
                border-radius: 8px;
                padding: 15px;
                .time{
                    font-size: 18px;
                    font-weight: 500;
                    text-align: left;
                    color: #666666;
                    letter-spacing: 0.9px;
                }
                .titleBox{
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    p{
                        width: 7px;
                        height: 7px;
                        background: #010129;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                    div{
                        height: 25px;
                        font-size: 18px;
                        font-weight: 500;
                        text-align: left;
                        color: #333333;
                        letter-spacing: 0.9px;
                    }
                }
                .txt{
                    width: 451px;
                    height: 47px;
                    font-size: 15px;
                    font-weight: 400;
                    text-align: left;
                    color: #666666;
                    line-height: 26px;
                    overflow:hidden;  //超出文本隐藏
                    text-overflow:ellipsis;  ///超出部分省略号显示
                    display:-webkit-box;  //弹性盒模型
                    -webkit-box-orient:vertical;  //上下垂直
                    -webkit-line-clamp:2;  //自定义行数
                }
            }
        }
    }
}
</style>