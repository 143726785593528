<template>
    <div class="empty">
        <img src="@/assets/icon15.png" />
        <div>{{description}}</div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props:['description'],
    setup() {
        
    },
})
</script>

<style lang="scss" scoped>
    .empty{
        width: 365px;
        height: 393px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
        padding: 50px 0;
        img{
            width: 365px;
            height: 365px;
        }
        div{
            width: 100%;
            height: 28px;
            font-size: 20px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: center;
            color: #f8b88f;
            letter-spacing: 1px;
        }
    }
</style>
