import { createRouter, createWebHistory } from "vue-router";
import Index from "../views/index.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Index,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
  {
    path: "/news",
    name: "news",
    component: () => import("../views/news.vue"),
  },
  {
    path: "/newsDetails",
    name: "newsDetails",
    component: () => import("../views/newsDetails.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../views/search.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/about.vue"),
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("../views/agreement.vue"),
  },
  {
    path: "/informationCenter",
    name: "informationCenter",
    component: () => import("../views/InformationCenter/index.vue"),
  },
  {
    path: "/infoDetails",
    name: "infoDetails",
    component: () => import("../views/InformationCenter/infoDetails.vue"),
  },
  {
    path: "/cart",
    name: "cart",
    component: () => import("../views/cart/index.vue"),
  },
  {
    path: "/settlement",
    name: "settlement",
    component: () => import("../views/cart/settlement.vue"),
  },
  {
    path: "/pay",
    name: "pay",
    component: () => import("../views/cart/pay.vue"),
  },
  {
    path: "/curriculum",
    name: "curriculum",
    component: () => import("../views/curriculum/index.vue"),
  },
  {
    path: "/courseDetails",
    name: "courseDetails",
    component: () => import("../views/curriculum/courseDetails.vue"),
  },
  {
    path: "/startIearning",
    name: "startIearning",
    component: () => import("../views/curriculum/startIearning.vue"),
  },
  // 考试中心
  {
    path: "/examinationCenter",
    name: "examinationCenter",
    component: () => import("../views/examinationCenter/index.vue"),
  },
  {
    path: "/onlineExamination",
    name: "onlineExamination",
    component: () => import("../views/examinationCenter/onlineExamination.vue"),
  },
  {
    path: "/examination",
    name: "examination",
    component: () => import("../views/examinationCenter/examination.vue"),
  },
  // 题库
  {
    path: "/questionBank",
    name: "questionBank",
    component: () => import("../views/questionBank/index.vue"),
  },
  {
    path: "/chapterExercise",
    name: "chapterExercise",
    component: () => import("../views/questionBank/chapterExercise.vue"),
  },
  // 我的学习
  {
    path: "/myStudy",
    name: "myStudy",
    component: () => import("../views/myStudy/index.vue"),
  },
  // 我的课程
  {
    path: "/myCourse",
    name: "myCourse",
    component: () => import("../views/myCourse/index.vue"),
  },
  {
    path: "/myCourseDetails",
    name: "myCourseDetails",
    component: () => import("../views/myCourse/myCourseDetails.vue"),
  },
  // 用户信息
  {
    path: "/userInformation",
    name: "userInformation",
    component: () => import("../views/userInformation/index.vue"),
  },
  // 我的积分
  {
    path: "/myPoints",
    name: "myPoints",
    component: () => import("../views/myPoints/index.vue"),
  },
  {
    path: "/pointsDetails",
    name: "pointsDetails",
    component: () => import("../views/myPoints/pointsDetails.vue"),
  },
  {
    path: "/pointsGoodsDetails",
    name: "pointsGoodsDetails",
    component: () => import("../views/myPoints/pointsGoodsDetails.vue"),
  },
  // 我的收藏
  {
    path: "/myCollection",
    name: "myCollection",
    component: () => import("../views/myCollection/index.vue"),
  },
  // 我的邀请
  {
    path: "/myInvitation",
    name: "myInvitation",
    component: () => import("../views/myInvitation.vue"),
  },
  // 我的发票
  {
    path: "/myInvoice",
    name: "myInvoice",
    component: () => import("../views/myInvoice/index.vue"),
  },
  {
    path: "/billingHistory",
    name: "billingHistory",
    component: () => import("../views/myInvoice/billingHistory.vue"),
  },
  {
    path: "/drawBill",
    name: "drawBill",
    component: () => import("../views/myInvoice/drawBill.vue"),
  },
  // 我的账户
  {
    path: "/myAccount",
    name: "myAccount",
    component: () => import("../views/myAccount/index.vue"),
  },
  {
    path: "/addBankCard",
    name: "addBankCard",
    component: () => import("../views/myAccount/addBankCard.vue"),
  },
  {
    path: "/myBankCard",
    name: "myBankCard",
    component: () => import("../views/myAccount/myBankCard.vue"),
  },
  {
    path: "/withdrawal",
    name: "withdrawal",
    component: () => import("../views/myAccount/withdrawal.vue"),
  },
  {
    path: "/myOrder",
    name: "myOrder",
    component: () => import("../views/myOrder/index.vue"),
  },
  // 协议
  {
    path: "/userAgreement",
    name: "userAgreement",
    component: () => import("../views/userAgreement.vue"),
  },
  // 错题解析
  {
    path: "/examinationDetails",
    name: "examinationDetails",
    component: () => import("../views/examinationCenter/examinationDetails.vue"),
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
