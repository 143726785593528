import axios from "axios";//创建一个axios的对象
import { useRouter } from "vue-router";
import { inject } from "vue";
let reload = inject('reload');
const router = useRouter();
//生成一个axios的实例
const http = axios.create({
	// baseURL:"https://sheke1.test03.qcw800.com",// baseURL会在发送请求的时候拼接在url参数前面
	baseURL:"https://api.bjjlsk.com",// baseURL会在发送请求的时候拼接在url参数前面
	
	timeout:6000,//请求超时
	// api_token:localStorage.getItem('token')?localStorage.getItem('token'):''
});
// http.defaults.headers['api_token'] = localStorage.getItem('token') || ''

http.interceptors.request.use(config => { 
    // console.log(config,'请求拦截');

  return config;
}, err => { 
  return Promise.reject(err)
})


//响应拦截器
http.interceptors.response.use(response => {
	if (response.config.url == '/api/user/login'&& response.data.data) {
		localStorage.setItem('token',response.data.data.api_token)
	}
	if (response.config.url == '/api/user/detail') {
		localStorage.setItem('userInfor',JSON.stringify(response.data.data))
	}

	// console.log(response,'响应拦截');
	

	return response;
  
  }, err => { 
	if(err.response.status == 401){
		console.log(err.response.status,'======');
		localStorage.clear();
		router.push('/');
	}
	// loading.close();
	return Promise.reject(err)
  })

export default http;//导出