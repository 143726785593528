<template>
    <div class="time">{{hour}}时{{minute}}分{{second}}</div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'

export default defineComponent({
    props:['timeStamp'],  //传值时间格式 2022-12-31 15:18:29
    setup(props) {
        const state = reactive({
            hour:0,
            minute:0,
            second:0
        })
        onMounted(()=>{
            setInterval( () => {
	            var countTime = new Date(props.timeStamp).getTime() - new Date().getTime();
                state.hour = Math.floor(countTime/3600000),
                state.minute = Math.floor(((countTime - state.hour*3600000))/60000),
                state.second = Math.floor((countTime - state.hour*3600000 - state.minute*60000)/1000),
                state.hour = (state.hour > 9 ? state.hour : "0" + state.hour),
                state.minute = (state.minute > 9 ? state.minute : "0" + state.minute),
                state.second = (state.second > 9 ? state.second : "0" + state.second);	
    		}, 1000);
        })
        return{
            ...toRefs(state)
        }
    },
})
</script>

<style lang="scss" scoped>
    .time{
        // width: 123px;
        // height: 28px;
        font-size: 20px;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 700;
        text-align: center;
        color: #f9634c;
        letter-spacing: 1.6px;
    }
</style>
